export const SHIPPING_METHOD = {
  PICKUP: 'PICKUP',
  SHIPPING: 'SHIPPING',
  DIRECT_HANDOVER: 'DIRECT_HANDOVER'
}

export const DELIVERY_TYPES = [
  {
    title: 'cart.delivery_and_shipping_method.pick_up',
    value: SHIPPING_METHOD.PICKUP,
    isDisabled: false,
    recommended: true
  },
  {
    title: 'cart.delivery_and_shipping_method.shipping',
    value: SHIPPING_METHOD.SHIPPING,
    isDisabled: false,
    recommended: false
  }
]
