import { differenceInDays, differenceInYears, format, parse } from 'date-fns'

/**
 * Formats the input ISO date string or Date object to DD/MM/YYYY format in Europe/Zurich time zone.
 *
 * @param {Date|string} input - The input ISO date string or Date object to be formatted.
 * @return {string} The formatted date string in DD/MM/YYYY format.
 */
export function formatISODateToDDMMYYYY(input) {
  if (!input) {
    return ''
  }
  let date
  if (input instanceof Date) {
    date = input
  } else if (typeof input === 'string') {
    const parsedDate = new Date(input)
    if (isNaN(parsedDate.getTime())) {
      const [year, month, day] = input.split('-')
      date = new Date(Date.UTC(year, month - 1, day))
    } else {
      date = new Date(
        Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate())
      )
    }
  }

  const options = {
    timeZone: 'Europe/Zurich',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }
  return date.toLocaleDateString('de-CH', options)
}

export function formatISODateToDDMMYYYYWithTime(date) {
  const newDate = new Date(date)
  return format(newDate, 'dd.MM.yyyy HH:mm')
}

export function getCurrentAge(dob, formatString = 'yyyy-MM-dd') {
  const date = parse(dob, formatString, new Date())
  return differenceInYears(new Date(), date)
}

export function calculateDaysDifferenceFromToday(targetDate) {
  const currentDate = new Date()
  const estimatedDate = new Date(targetDate)
  return differenceInDays(estimatedDate, currentDate)
}

export function calculateDayDifferences(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  return differenceInDays(end, start)
}

export function formatDateToYYYYMMDD(inputDate) {
  const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'
  }

  const dateString = inputDate.toString()
  const dateWithoutTimezone = dateString.replace(/\(.+?\)/, '').trim()
  const dateArray = dateWithoutTimezone.split(' ')

  const year = dateArray[3]
  const month = months[dateArray[1]]
  const day = dateArray[2]

  return `${year}-${month}-${day}`
}

export function timestampToYYYYMMDD(timestamp) {
  // Create a new Date object from the timestamp
  let date = new Date(timestamp)

  // Extract the day, month, and year
  let day = date.getDate()
  let month = date.getMonth() + 1 // Months are zero based
  let year = date.getFullYear()

  // Ensure day and month are formatted to have two digits
  day = day < 10 ? '0' + day : day
  month = month < 10 ? '0' + month : month

  // Return the formatted date
  return `${day}.${month}.${year}`
}

export function timesDiffInCETFullDay(diffInSeconds) {
  // get to days date by midnight
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const timeBefore = new Date(today - diffInSeconds)

  // Convert to CET (UTC+1 or UTC+2 with DST)
  const timeOffset = timeBefore.getTimezoneOffset() // in minutes
  const cetOffset = 60 // CET is UTC+1, so 60 minutes
  const adjustedTimeAgo = new Date(timeBefore.getTime() - (timeOffset - cetOffset) * 60000)

  return adjustedTimeAgo.toISOString().slice(0, -1) + '+01:00' // appends CET offset
}

// YYYY-MM-DD
export function validateZapDate(dateString) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) return false

  // Parse the date parts to integers
  const parts = dateString.split('-')
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)

  // Check the ranges of month and day
  if (month < 1 || month > 12) return false

  const daysInMonth = new Date(year, month, 0).getDate()
  if (day < 1 || day > daysInMonth) return false

  return true
}
