<template>
  <div>
    <div class="dropdown-selection">
      <sl-input
        ref="searchInputRef"
        v-model="query"
        :placeholder="$t('customer.find_customer')"
        size="medium"
        enterkeyhint="search"
        class="customerSearch"
        data-testid="customer-search-input"
        clearable
        autocomplete="off"
        @click="handleClick"
        @sl-clear="handleClearInput"
        @sl-focus="handleCustomersFromLocalStorage"
      >
        <sl-icon slot="prefix" name="search"></sl-icon>
      </sl-input>
      <sl-dropdown
        :open="uiStore.showCustomerSearchDialog"
        placement="bottom-start"
        style="z-index: 699"
        @sl-after-hide="handleCloseDropdown"
      >
        <sl-menu class="resMenu">
          <sl-menu-item
            class="add-new-customer"
            data-testid="add-new-customer-menu"
            @click="$emit('openAddNewCustomer')"
          >
            <PhPlus slot="prefix" :size="16" weight="bold" />
            {{ $t('customer.add_new_customer') }}
          </sl-menu-item>
          <template v-if="searchNoResults && !isSearching">
            <sl-divider></sl-divider>
            <sl-menu-item class="no-customer-found" data-testid="no-customer-found-result"
              ><sl-icon slot="prefix" name="exclamation-triangle"></sl-icon
              >{{ $t('customer.no_customer_found') }}</sl-menu-item
            >
          </template>
          <div
            v-if="isSearching"
            style="padding-left: 24px; display: flex; align-items: center; gap: 12px"
          >
            <sl-spinner></sl-spinner>{{ $t('globals.searching') }}...
          </div>
          <template v-if="customers.length > 0">
            <sl-divider></sl-divider>
            <sl-menu-item
              v-for="c in customers.slice(0, 3)"
              :key="c.d"
              class="resItem"
              @click="loadCustomer(c.id)"
            >
              <div class="resItem__layout">
                {{ c.first_name }} {{ c.last_name }}
                <div class="text-color-neutral">{{ c.email }}</div>
              </div>
            </sl-menu-item>
            <div
              v-if="customers.length > 3"
              style="
                padding: var(--sl-spacing-small) var(--sl-spacing-large) var(--sl-spacing-small)
                  var(--sl-spacing-large);
              "
            >
              <sl-button size="small" @click="showMore = true"
                >{{ $t('globals.all') }} ({{ customers.length }})</sl-button
              >
            </div>
          </template>
          <template v-else-if="!isSearching && !searchNoResults && lastClients.length > 0">
            <sl-divider></sl-divider>
            <sl-menu-label class="last-clients-label">{{
              $t('customer.last_clients')
            }}</sl-menu-label>
            <sl-menu-item v-for="(c, i) in lastClients" :key="i" @click="loadCustomer(c.id)">
              <div
                class="menu-item"
                :class="[
                  {
                    'menu-item--danger': [
                      CUSTOMER_RESTRICTION.BANNED,
                      CUSTOMER_RESTRICTION.BLOCKED
                    ].includes(c?.restrictions)
                  }
                ]"
              >
                <div class="resItem__layout">
                  {{ c.firstName }} {{ c.lastName }}
                  <div class="text-color-neutral">{{ c.email }}</div>
                </div>
                <template v-if="c.restrictions === CUSTOMER_RESTRICTION.PREPAYMENT">
                  <sl-tag variant="warning" size="small">{{
                    $t('customer.form.restriction.prepayment.title')
                  }}</sl-tag>
                </template>
                <template v-if="c.restrictions === CUSTOMER_RESTRICTION.BANNED">
                  <sl-tag variant="danger" size="small">{{
                    $t('customer.form.restriction.banned.title')
                  }}</sl-tag>
                </template>
                <template v-if="c.restrictions === CUSTOMER_RESTRICTION.BLOCKED">
                  <sl-tag variant="danger" size="small">{{
                    $t('customer.form.restriction.blocked.title')
                  }}</sl-tag>
                </template>
              </div>
            </sl-menu-item>
          </template>
        </sl-menu>
      </sl-dropdown>
    </div>

    <sl-dialog
      :open="showMore"
      :label="`${$t('customer.allResults')} (${customers.length})`"
      style="--width: 880px"
      no-footer
      @sl-request-close="((showMore = false), (customers = []))"
    >
      <EasyDataTable
        :headers="[
          {
            text: $t('customer.table.nameEmail'),
            value: 'first_name',
            sortable: true
          },
          { text: $t('customer.address'), value: 'address', sortable: false },
          {
            text: $t('customer.form.label.country'),
            value: 'country',
            sortable: true
          },
          { text: $t('customer.form.label.birth_date'), value: 'birth_date', sortable: true },
          { text: $t('customer.table.action'), value: 'action', sortable: false }
        ]"
        :items="customers"
        :hide-rows-per-page="true"
        :rows-per-page="100"
        :hide-footer="true"
        table-class-name="wings-table customer-table"
      >
        <template #item-first_name="slotProps">
          {{ slotProps.first_name }} {{ slotProps.last_name }}
          <div class="text-color-neutral">{{ slotProps.email }}</div>
        </template>
        <template #item-birth_date="slotProps">
          {{ slotProps.birth_date ? timestampToYYYYMMDD(slotProps.birth_date) : '' }}
          <div v-if="slotProps.birth_date" class="text-color-neutral">
            {{ getCurrentAge(timestampToYYYYMMDD(slotProps.birth_date), 'dd.mm.yyyy') }}
            {{ $t('globals.years') }}
          </div>
        </template>
        <template #item-address="slotProps">
          {{ slotProps.address_line_1 }}
          <div class="text-color-neutral">
            {{ slotProps.address_zip_code }} {{ slotProps.address_city }}
          </div>
        </template>
        <template #item-action="slotProps">
          <sl-button
            variant="default"
            @click="
              (loadCustomer(slotProps.id), (showMore = false), (customers = []), (query = ''))
            "
            >{{ $t('globals.select') }}</sl-button
          >
        </template>
      </EasyDataTable>
    </sl-dialog>

    <sl-dialog
      v-if="uiStore.showCustomerBannedDialog"
      :label="$t('cart.alert.attention')"
      class="dialog-overview"
      open
      @sl-request-close="uiStore.showCustomerBannedDialog = false"
    >
      <img src="https://media.giphy.com/media/l2Je3fAJ02BkvLYEE/giphy.gif" alt="banned" />
      {{ $t('cart.alert.customerResctrictionBanned') }}
      <sl-button
        slot="footer"
        variant="primary"
        @click="uiStore.showCustomerBannedDialog = false"
        >{{ $t('globals.close') }}</sl-button
      >
    </sl-dialog>

    <sl-dialog
      v-if="uiStore.showEmailBannedOrInvalid"
      :label="$t('cart.alert.attention')"
      class="dialog-overview"
      open
      @sl-request-close="uiStore.showEmailBannedOrInvalid = false"
    >
      {{ $t('cart.alert.customerEmailInvalidOrBanned') }}
      <sl-button
        slot="footer"
        variant="default"
        @click="uiStore.showEmailBannedOrInvalid = false"
        >{{ $t('globals.close') }}</sl-button
      ><sl-button
        slot="footer"
        variant="primary"
        @click="
          ((customerStore.isCustomerProfileEdit = true),
          (uiStore.currentCustomerDrawer = 'profile'),
          (uiStore.showCustomerCreationEditDialog = true),
          (uiStore.showEmailBannedOrInvalid = false))
        "
        >{{ $t('customer.menu.customer_profile') }}</sl-button
      >
    </sl-dialog>

    <CustomerCartDialog
      :is-choose-cart-visible="isChooseCartVisible && !hideChooseCartDialog"
      :is-loading-customer="isLoadingCustomer"
      @close-dialog="closeDialog"
      @sl-request-close="uiStore.showCustomerBannedDialog = false"
    />
  </div>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import '@shoelace-style/shoelace/dist/components/format-date/format-date'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/input/input'
import '@shoelace-style/shoelace/dist/components/spinner/spinner'

import CustomerCartDialog from '@/components/Customer/CustomerCartDialog/CustomerCartDialog.vue'
import { useCustomer } from '@/composables/useCustomer.js'
import {
  getCustomersFromLocalStorage,
  setCustomerInTheLocalStorage
} from '@/composables/useCustomerLocalStorage'
import { useNotification } from '@/composables/useNotification.js'
import { CUSTOMER_RESTRICTION } from '@/constants/customer.js'
import { useCartStore } from '@/stores/cart.js'
import { useConfiguratorStore } from '@/stores/configurator'
import { useCustomerStore } from '@/stores/customer.js'
import { useDeviceStore } from '@/stores/device.js'
import { useUiStore } from '@/stores/ui.js'
import { getCurrentAge, timestampToYYYYMMDD } from '@/utils/dateTime'
import { PhPlus } from '@phosphor-icons/vue'
import { watchDebounced } from '@vueuse/core'
import { useTypesense } from '@/composables/useTypesense'
import { useStockTransfer } from '@/composables/useStockTransfer.js'
import { inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import EasyDataTable from 'vue3-easy-data-table'

const cl = inject('cl')
const router = useRouter()
const customerStore = useCustomerStore()
const configuratorStore = useConfiguratorStore()
const uiStore = useUiStore()
const deviceStore = useDeviceStore()
const cartStore = useCartStore()
const notification = useNotification()
const { loadCustomerById } = useCustomer()
const { stockTransferSetDetails } = useStockTransfer()
const searchInputRef = ref()
const query = ref('')
const customers = ref([])
const isSearching = ref(false)
const searchNoResults = ref(false)
const isChooseCartVisible = ref(false)
const hideChooseCartDialog = ref(false)
const isLoadingCustomer = ref(false)
const lastClients = ref([])
const redirectAfterLoad = ref(false)
const showMore = ref(false)

const carts = ref([])

const typesense = useTypesense()

defineEmits(['openAddNewCustomer'])

watchDebounced(
  query,
  () => {
    searchCustomer()
  },
  { debounce: 700, maxWait: 4000 }
)

async function searchCustomer() {
  if (query.value.length < 1) {
    return
  }

  if (isEightDigitNumber(query.value)) {
    // find order where id is equal to query.value. and return the order number.
    const order = await cl.orders.list({
      include: ['customer'],
      filters: { number_eq: query.value },
      fields: { orders: ['number', 'customer_email', 'customer'], customers: ['id'] }
    })

    if (order[0]?.id) {
      handleClearInput()
      if (order[0].customer_email !== customerStore.customer?.email) {
        hideChooseCartDialog.value = true
        await loadCustomer(order[0].customer.id)
      }

      customerStore.customerOrderId = order[0].id
      uiStore.$patch({
        currentCustomerDrawer: 'orders'
      })

      uiStore.$patch({
        showCustomerOrderDetails: true
      })
      return
    }
  } else if (isStockTransferNumber(query.value)) {
    const stockTransfer = await cl.stock_transfers.list({
      filters: { number_eq: query.value },
      include: [
        'sku',
        'origin_stock_location.address',
        'destination_stock_location',
        'shipment.order.customer',
        'shipment.order.billing_address',
        'shipment.attachments',
        'line_item.line_item_options'
      ]
    })

    if (stockTransfer[0]?.id) {
      handleClearInput()
      await stockTransferSetDetails(stockTransfer[0], false)
      uiStore.$patch({
        showStockTransferDetails: true
      })
      return
    }
  }

  try {
    isSearching.value = true
    customers.value = []

    const searchResults = await typesense
      .client()
      .collections(import.meta.env.VITE_TYPESENSE_CUSTOMERS_COLLECTION)
      .documents()
      .search({
        q: query.value,
        query_by:
          'first_name,last_name,email,phone,address_line_1,address_line_2,address_zip_code,address_city',
        sort_by: `_text_match:desc`,
        limit: 50,
        include_fields: `id,first_name,last_name,email,phone,gender,language,birth_date,status,tags,restrictions,address_line_1,address_line_2,address_zip_code,address_city,country,reference`
      })

    searchNoResults.value = searchResults.hits.length === 0
    // if searchResults.hits.length > 0 then set customers.value = searchResults.hits. but only push the property document to the customers array.
    customers.value =
      searchResults.hits.length > 0 ? searchResults.hits.map((hit) => hit.document) : []

    isSearching.value = false
  } catch (error) {
    isSearching.value = false
  }
}

function isEightDigitNumber(input) {
  // Check if input is a string and has exactly 8 characters
  if (typeof input !== 'string' || input.length !== 8) {
    return false
  }

  // Check if every character in the input string is a digit (0-9)
  for (let i = 0; i < input.length; i++) {
    if (input[i] < '0' || input[i] > '9') {
      return false // Non-digit character found
    }
  }

  // If all characters are digits and length is exactly 8, return true
  return true
}

function isStockTransferNumber(input) {
  // check for 6 digit number with regex
  return /^[0-9]{6}$/.test(input)
}

function closeDialog() {
  isChooseCartVisible.value = false
  customers.value = []

  if (uiStore.showCart) {
    uiStore.showCart = false
    router.push({ name: 'home' })
  }

  if (redirectAfterLoad.value) {
    router.push({ name: 'home' })
  }
}

async function loadCustomer(id) {
  let backUpCart = null
  redirectAfterLoad.value = customerStore.customer?.id ? true : false
  // anonymous cart present
  if (customerStore.getCurrentCart && !customerStore.customer?.id && !hideChooseCartDialog.value) {
    backUpCart = JSON.parse(JSON.stringify(customerStore.getCurrentCart))
    carts.value.push(backUpCart)
  }

  customerStore.reset()

  if (configuratorStore.config.prescription) {
    configuratorStore.$patch({
      config: {
        prescription: {
          id: null,
          prescription: {},
          prisma: ''
        }
      }
    })
  }

  if (configuratorStore.config.centering) {
    configuratorStore.$patch({
      config: {
        centering: {
          hsaGlass: null,
          hsaPrescription: null,
          fsw: null,
          inc: null,
          channel: null,
          viewHeightLeft: null,
          viewHeightRight: null
        }
      }
    })
  }

  isLoadingCustomer.value = true
  const customer = await loadCustomerById(id)
  if (!customer) {
    isLoadingCustomer.value = false
    notification.showNotification({
      message: 'Customer could not be loaded.',
      variant: 'danger',
      duration: 6000,
      icon: 'person-x'
    })
    return
  }

  const billingAddress = customer?.customer_addresses?.filter(
    (item) => item.address.metadata?.is_default_billing
  )
  const shippingAddress = customer?.customer_addresses?.filter(
    (item) => item.address.metadata?.is_default_shipping
  )

  if (billingAddress?.length) {
    cartStore.setBillingAddress(billingAddress[0].address)
  } else {
    if (customer?.customer_addresses.length > 0) {
      cartStore.setBillingAddress(customer.customer_addresses[0].address)
    } else {
      cartStore.setBillingAddress(null)
    }
  }

  if (shippingAddress?.length) {
    cartStore.setShippingAddress(shippingAddress[0].address)
  } else {
    if (customer?.customer_addresses.length > 0) {
      cartStore.setShippingAddress(customer.customer_addresses[0].address)
    } else {
      cartStore.setShippingAddress(null)
    }
  }

  if (customer?.metadata?.restrictions === CUSTOMER_RESTRICTION.BANNED) {
    isLoadingCustomer.value = false
    setTimeout(() => {
      uiStore.showCustomerBannedDialog = true
    }, 300)
  }
  if (customer?.metadata?.invalid_email) {
    isLoadingCustomer.value = false
    setTimeout(() => {
      uiStore.showEmailBannedOrInvalid = true
    }, 300)
  }

  if (!backUpCart) {
    uiStore.showCart = false
    carts.value = await getCarts(id)
  }

  if (customer) {
    setCustomerInTheLocalStorage({
      id: customer.id,
      firstName: customer.metadata?.first_name,
      lastName: customer.metadata?.last_name,
      email: customer.email,
      restrictions: customer.metadata?.restrictions
    })
  }

  isLoadingCustomer.value = false

  if (customer) {
    // resets guest account for the cart
    customerStore.setGuestAccount({})
    customerStore.setCustomer(customer)
    customerStore.setCarts(carts.value)
    customerStore.setCustomerAddress(null)

    if (backUpCart) {
      // update order and set customer
      const cart = await cl.orders.update({
        id: backUpCart.id,
        market: cl.markets.relationship(deviceStore.settings.marketId),
        customer: cl.customers.relationship(customerStore.customer.id),
        language_code:
          customerStore.customer?.metadata?.language?.toLowerCase() ||
          deviceStore.settings.language.toLowerCase()
      })

      if (!cart) {
        notification.showNotification({
          message: 'Cart could not be assigned to this customer.',
          icon: 'x-circle'
        })
      } else {
        carts.value = await getCarts(id)
      }
    }

    // if customer is banned prevent displaying cart dialog
    if (uiStore.showCustomerBannedDialog) {
      isChooseCartVisible.value = false
      customerStore.isCustomerSelected = true
      return
    }

    if (carts.value.length > 0) {
      if (backUpCart) {
        customerStore.setCurrentCart(carts.value[0])
      } else {
        isChooseCartVisible.value = true
      }
    } else if (carts.value.length === 1) {
      customerStore.setCurrentCart(carts.value[0])
      isChooseCartVisible.value = false
      notification.showNotification({
        message: 'Customer has been loaded.',
        icon: 'person-down'
      })
    }
    cartStore.shippingAddressSetOnCart = false
    customerStore.isCustomerSelected = true
  } else {
    notification.showNotification({
      message: 'Customer could not be loaded.',
      variant: 'danger',
      duration: 6000,
      icon: 'person-x'
    })
  }

  if (redirectAfterLoad.value & !isChooseCartVisible.value) {
    router.push({ name: 'home' })
    uiStore.showCart = false
  }
}

async function getCarts(id) {
  return await cl.orders.list({
    include: [
      'attachments',
      'line_items.item',
      'line_items.line_item_options.sku_option',
      'market',
      'shipping_address',
      'billing_address',
      'shipments.available_shipping_methods',
      'tags'
    ],
    fields: {
      markets: ['code', 'metadata'],
      orders: [
        'attachments',
        'subtotal_amount_float',
        'total_amount_float',
        'currency_code',
        'fulfillment_status',
        'payment_status',
        'coupon_code',
        'status',
        'number',
        'created_at',
        'shipping_address',
        'billing_address',
        'metadata',
        'line_items',
        'market',
        'tags',
        'skus_count',
        'shipments',
        'gift_card_code',
        'total_amount_with_taxes_float',
        'shipping_amount_float'
      ]
    },
    filters: {
      customer_id_eq: `${id}`,
      status_matches_any: 'draft,pending',
      archived_at_null: true,
      market_merchant_address_country_code_eq: deviceStore.settings.country.toUpperCase(),
      line_items_item_type_eq: 'skus'
    },
    sort: { updated_at: 'desc' },
    pageSize: 3
  })
}

function handleCustomersFromLocalStorage() {
  lastClients.value = getCustomersFromLocalStorage()
}

function handleClearInput(event = null) {
  if (event) {
    event.stopPropagation()
  }
  uiStore.showCustomerSearchDialog = false
  query.value = ''
  customers.value = []
  isSearching.value = false
}

function handleCloseDropdown(event) {
  event.stopPropagation()
  query.value = ''
  uiStore.showCustomerSearchDialog = false
  searchNoResults.value = false
  handleCustomersFromLocalStorage()
}

function handleClick() {
  handleCustomersFromLocalStorage()
  searchInputRef.value.focus()
  uiStore.showCustomerSearchDialog = true
  searchNoResults.value = false
}

watch(
  () => uiStore.showCustomerSearchDialog,
  (value) => {
    if (value) {
      searchInputRef.value.focus()
    }
  }
)
</script>

<style src="./CustomerSearch.css" scoped />
<style scoped lang="postcss">
sl-menu-item {
  &::part(base) {
    font-size: var(--sl-font-size-small);
  }
}
</style>

<style lang="postcss">
.customer-table {
  font-size: var(--sl-font-size-small);
  line-height: var(--sl-line-height-dense);

  th {
    font-weight: 400;
  }

  .vue3-easy-data-table__header th.sortable.none .sortType-icon {
    opacity: 1 !important;
  }
}
</style>
