import { computed, inject } from 'vue'
import { getErrorMessage } from '@/utils/error.js'
import { useNotification } from '@/composables/useNotification.js'
import { useDeviceStore } from '@/stores/device.js'

export function useNotes() {
  const cl = inject('cl')
  const { showNotification } = useNotification()
  const deviceStore = useDeviceStore()
  const currentStoreReference = computed(() => deviceStore.settings.warehouse)

  async function fetchOrderNotes(id) {
    try {
      return await cl.orders.attachments(id, {
        filters: {
          reference_origin_eq: 'app-orders--note',
          reference_not_eq: 'app-orders--deleted-note'
        },
        include: ['attachable'],
        fields: {
          orders: ['number']
        },
        sort: { updated_at: 'desc' }
      })
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function fetchCustomerNotes(id, { errorNotify = true, errorCatch = true } = {}) {
    try {
      return await cl.customers.attachments(id, {
        filters: {
          reference_origin_eq: 'app-orders--note',
          reference_not_eq: 'app-orders--deleted-note'
        },
        include: ['attachable'],
        fields: {
          customers: ['email']
        },
        sort: { updated_at: 'desc' }
      })
    } catch (error) {
      if (errorNotify) {
        showNotification({
          message: getErrorMessage(error),
          variant: 'danger'
        })
      }
      if (!errorCatch) {
        throw error
      }
    }
  }

  async function createOrderNote(id, note, { metadata = {} } = {}) {
    if (note === '') {
      return
    }
    try {
      await cl.attachments.create({
        name: deviceStore.settings.currentEmployee.name,
        description: note,
        reference: currentStoreReference.value,
        reference_origin: 'app-orders--note',
        metadata: {
          employee_created: {
            eid: deviceStore.settings.currentEmployee.eid,
            name: deviceStore.settings.currentEmployee.name
          },
          ...metadata
        },
        attachable: cl.orders.relationship(id)
      })
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function createCustomerNote(id, note, { errorNotify = true, errorCatch = true, metadata = {} } = {}) {
    if (note === '') {
      return
    }
    try {
      await cl.attachments.create({
        name: deviceStore.settings.currentEmployee.name,
        description: note,
        reference: currentStoreReference.value,
        reference_origin: 'app-orders--note',
        metadata: {
          employee_created: {
            eid: deviceStore.settings.currentEmployee.eid,
            name: deviceStore.settings.currentEmployee.name
          },
          ...metadata
        },
        attachable: cl.customers.relationship(id)
      })
    } catch (error) {
      if (errorNotify) {
        showNotification({
          message: getErrorMessage(error),
          variant: 'danger'
        })
      }
      if (!errorCatch) {
        throw error
      }
    }
  }

  return {
    fetchCustomerNotes,
    fetchOrderNotes,
    createOrderNote,
    createCustomerNote
  }
}
