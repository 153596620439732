<template>
  <div>
    <sl-dropdown ref="dropdown" placement="bottom-start" distance="2" @sl-show="focusInput">
      <sl-button slot="trigger" class="employeeSwitch" caret>
        {{ deviceStore.settings.warehouse }}
      </sl-button>
      <sl-menu>
        <sl-input
          ref="searchInput"
          v-model="query"
          :placeholder="$t('deviceSettings.searchMarket')"
          size="medium"
        >
          <sl-icon slot="prefix" name="search"></sl-icon>
        </sl-input>
        <sl-menu-item style="pointer-events: none">
          <div class="listItem" style="line-height: 1.36">
            <b>{{ deviceStore.settings.currentEmployee.name }}</b
            ><br />
            <div class="text-color-neutral">{{ deviceStore.settings.currentEmployee.email }}</div>
          </div>
        </sl-menu-item>
        <sl-divider></sl-divider>
        <template v-if="query.length > 0 && marketsList.length > 0">
          <sl-menu-item v-for="item in marketsList" :key="item.id" @click.stop="setMarket(item)">
            <div class="storeItem">
              <div>{{ item.code }}</div>
              <div>{{ item.name }}</div>
            </div>
          </sl-menu-item>
        </template>
        <template v-else-if="lastMarkets.length > 0 && query.length === 0">
          <sl-menu-item
            v-for="(item, index) in lastMarkets"
            :key="index"
            @click.stop="setMarket(item)"
          >
            <div class="storeItem">
              <div>{{ item.code }}</div>
              <div>{{ item?.name }}</div>
            </div>
          </sl-menu-item>
        </template>
        <div v-else-if="noRes" class="noResults">{{ $t('deviceSettings.noMarketFound') }}</div>
        <div v-else class="noResults">{{ $t('deviceSettings.enterSearchTerm') }}</div>
      </sl-menu>
    </sl-dropdown>
    <sl-dialog :open="loading" no-header no-footer>
      <div class="loaderLayout">
        <sl-spinner style="font-size: 2rem; --track-width: 3px"></sl-spinner
        >{{ $t('deviceSettings.pleaseWait') }}
      </div>
    </sl-dialog>
  </div>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label'
import '@shoelace-style/shoelace/dist/components/spinner/spinner'
import '@shoelace-style/shoelace/dist/components/input/input'
import '@shoelace-style/shoelace/dist/components/divider/divider'

import { ref, onMounted } from 'vue'
import { useDeviceStore } from '@/stores/device'
import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useMarket } from '@/composables/useMarket.js'
import { watchDebounced } from '@vueuse/core'

const dropdown = ref()
const searchInput = ref()
const deviceStore = useDeviceStore()
const cartStore = useCartStore()
const customerStore = useCustomerStore()
const { getMarketsList, getMarketSettingsFromWarehouse } = useMarket()
const query = ref('')
const marketsList = ref([])
const lastMarkets = ref([])
const loading = ref(false)
const noRes = ref(false)

defineEmits(['closeLoginDialog'])

onMounted(() => {
  // Load last markets from localStorage
  const storedMarkets = localStorage.getItem('lastMarkets')
  if (storedMarkets) {
    try {
      lastMarkets.value = JSON.parse(storedMarkets)
    } catch (e) {
      console.error('Error parsing lastMarkets from localStorage:', e)
      lastMarkets.value = []
    }
  }
})

watchDebounced(
  () => query.value,
  async (newVal, oldVal) => {
    if (newVal !== oldVal && newVal.length > 1) {
      await listMarkets(query.value)
    }

    if (newVal.length === 0) {
      resetMarketList()
    }
  },
  { debounce: 400, maxWait: 2000 }
)

async function listMarkets(query) {
  if (query === '') {
    marketsList.value = []
    return
  }

  try {
    marketsList.value = await getMarketsList('name_or_code_cont_any', query)
    if (marketsList.value.length === 0) {
      noRes.value = true
    } else {
      noRes.value = false
    }
  } catch (e) {
    marketsList.value = []
    throw new Error(e)
  }
}

function focusInput() {
  setTimeout(() => {
    searchInput.value.focus()
  }, 200)
}

function resetMarketList() {
  marketsList.value = []
}

/**
 * Update the lastMarkets array and save it to localStorage.
 * @param {Object} market - The market object to add to the lastMarkets array
 */
function updateLastMarkets(market) {
  const marketEntry = {
    code: market.code,
    name: market.name,
    id: market.id
  }

  const filteredMarkets = lastMarkets.value.filter((item) => item.code !== market.code)
  filteredMarkets.unshift(marketEntry)
  lastMarkets.value = filteredMarkets.slice(0, 5)
  localStorage.setItem('lastMarkets', JSON.stringify(lastMarkets.value))
}

/**
 * Sets the market for the user. This is used when the user selects a market
 * from the dropdown. It updates the last markets history and sets the
 * deviceStore.settings with the new settings.
 *
 * @param {Object} warehouse - The warehouse object selected by the user
 * @returns {Promise<void>}
 */
async function setMarket(warehouse) {
  cartStore.$reset()
  customerStore.reset()
  loading.value = true
  dropdown.value.hide()
  query.value = ''
  marketsList.value = []

  updateLastMarkets(warehouse)

  const settings = await getMarketSettingsFromWarehouse(warehouse.name)
  settings.deviceType = 'Personal'
  settings.deviceId = deviceStore.user.email

  deviceStore.$patch({
    settings: {
      ...deviceStore.settings,
      ...settings
    }
  })
  localStorage.setItem('marketSettings', JSON.stringify(settings))

  setTimeout(() => {
    loading.value = false
  }, 1000)
}
</script>

<style lang="postcss" scoped>
sl-menu {
  padding-top: 0;
  padding-bottom: 0;
}

sl-divider {
  margin-top: 0;
}

sl-input {
  &::part(base) {
    border-radius: 0;
    border-width: 0 0 1px 0;
    font-size: 14px;
  }

  .input__prefix ::slotted(*) {
    margin-left: 30px;
  }
}

sl-menu-item {
  &::part(base) {
    padding: var(--sl-spacing-x-small) var(--sl-spacing-small);
  }

  &::part(checked-icon),
  &::part(submenu-icon) {
    display: none;
  }
}

sl-menu-label {
  &::part(base) {
    font-size: var(--sl-font-size-small);
    color: var(--sl-color-neutral-500);
    padding: var(--sl-spacing-x-small) var(--sl-spacing-small);
  }
}

.storeItem {
  display: flex;
  gap: var(--sl-spacing-medium);

  div:nth-child(1) {
    width: 40px;
  }
}

.noResults {
  padding: var(--sl-spacing-medium);
  text-align: center;
  color: var(--sl-color-neutral-500);
  font-size: var(--sl-font-size-small);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderLayout {
  display: flex;
  flex-direction: column;
  gap: var(--sl-spacing-medium);
  justify-content: center;
  align-items: center;
  height: 100px;
  padding-top: var(--sl-spacing-large);
}
</style>
