import { createRouter, createWebHistory } from 'vue-router'
import { useUiStore } from '@/stores/ui'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView/HomeView.vue')
    },
    {
      path: '/cdn-cgi/access/authorized',
      redirect: () => {
        return { path: '/' }
      }
    },
    {
      path: '/appointments',
      name: 'appointments',
      component: () => import('@/views/AppointmentsView/AppointmentsView.vue')
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/ClientsView/ClientsView.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/ClientsView/ClientsView.vue')
    },
    {
      path: '/workbench/:storeReference',
      name: 'workbench',
      component: () => import('@/views/WorkbenchView/WorkbenchView.vue')
    },
    {
      path: '/shipments/:trackingId',
      name: 'shipment-details',
      component: () => import('@/views/ShipmentDetailView/ShipmentDetailView.vue')
    },
    {
      path: '/incoming-goods/:storeReference',
      name: 'incoming-goods',
      component: () => import('@/views/IncomingGoodsView/IncomingGoodsView.vue')
    },
    {
      path: '/outgoing-stock-transfers/:storeReference',
      name: 'outgoing-stock-transfers',
      component: () => import('@/views/OutgoingShipmentView/OutgoingShipmentView.vue')
    },
    {
      path: '/stock-management',
      name: 'stock-management',
      meta: {
        permissions: 'stockManagement'
      },
      component: () => import('@/views/StockManagementView/StockManagementView.vue')
    },
    {
      path: '/inventory',
      name: 'inventory',
      meta: {
        permissions: 'inventory',
        showAppHeader: false
      },
      component: () => import('@/views/InventoryView/InventoryView.vue')
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/ReportsView/ReportsView.vue')
    },
    {
      path: '/order-success',
      name: 'order-success',
      component: () => import('@/views/OrderSuccessView/OrderSuccessView.vue')
    },
    {
      path: '/product/:modelId/:sku',
      name: 'product',
      component: () => import('@/views/ProductView/ProductView.vue')
    },
    {
      path: '/promotions',
      name: 'promotions',
      meta: {
        permissions: 'promotions'
      },
      component: () => import('@/views/PromotionsView/PromotionsView.vue')
    },
    {
      path: '/gift-cards-management',
      name: 'gift-cards-management',
      meta: {
        permissions: 'gift_cards'
      },
      component: () => import('@/views/GiftCardManagementView/GiftCardManagementView.vue')
    },
    {
      path: '/day-overview',
      name: 'dayOverview',
      component: () => import('@/views/DayOverview/DayOverview.vue')
    },
    {
      path: '/day-overview/orders',
      name: 'dayOverviewOrders',
      component: () => import('@/views/DayOverview/DayOverviewOrders.vue')
    },
    {
      path: '/day-overview/refunds',
      name: 'dayOverviewRefunds',
      component: () => import('@/views/DayOverview/DayOverviewRefunds.vue')
    },
    {
      path: '/day-overview/cash-registry',
      name: 'dayOverviewCashRegistry',
      component: () => import('@/views/DayOverview/DayOverviewCashRegistry.vue')
    },
    {
      path: '/order-detail/:number',
      name: 'order-detail',
      component: () => import('@/views/OrderDetailView/OrderDetailView.vue')
    },
    {
      path: '/terminal-management',
      name: 'terminal-management',
      component: () =>
        import('@/views/AdminView/AdminTerminalManagementView/AdminTerminalManagementView.vue')
    },
    {
      path: '/task-execution',
      name: 'task-execution',
      component: () => import('@/views/AdminView/AdminTaskExecution/AdminTaskExecutionView.vue')
    }
  ]
})

router.beforeEach((to) => {
  const uiStore = useUiStore()
  uiStore.showAppHeader = to.meta?.showAppHeader !== undefined ? to.meta.showAppHeader : true
})

export default router
