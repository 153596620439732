<template>
  <div class="flags">
    <sl-badge
      v-for="flag in customerFlags.storedToDisplayed"
      :key="flag"
      pill
      class="flags__item"
      :class="[
        `flags__item--${flag}`
      ]"
    >
      {{ $t(`customer_flags.${flag}.label`) }}
    </sl-badge>
  </div>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/badge/badge'

import { computed } from 'vue'

import { useCustomerFlags } from '@/composables/useCustomerFlags'

const props = defineProps({
  items: {
    type: Array,
    default() {
      return []
    }
  }
})

const items = computed(() => {
  return (props.items || []).map(item => item.name)
})

const customerFlags = await useCustomerFlags({
  storedList: items
})
</script>

<style scoped src="./CustomerFlags.css"></style>
