<template>
  <div class="form-input" :class="[$attrs.class, { 'form-input--invalid': errorMessage.length }]">
    <label v-if="$attrs.label?.length">
      <span
        class="form-input__label-wrapper"
        :class="{ 'form-input__label-wrapper--invalid': errorMessage.length }"
      >
        <span class="label-title">{{ $attrs.label }}</span>
        <span v-if="$attrs['label-optional']" class="label-subtitle">{{
          $attrs['label-optional']
        }}</span>
      </span>
    </label>
    <div class="form-input__field">
      <slot name="pre" />
      <sl-input v-model="localValue" v-bind="$attrs" label="" @keydown.tab="preventTabPropagation" />
      <slot name="post" />
    </div>
    <span v-if="errorMessage.length" class="form-input__error-message">{{ errorMessage }}</span>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import '@shoelace-style/shoelace/dist/components/input/input'

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  tabindex: {
    type: String,
    default: '0'
  }
})

const errorMessage = computed(() => props.error)
const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

function preventTabPropagation(event) {
  if (event.key === 'Tab') {
    event.stopPropagation()
  }
}
</script>
<style src="@/components/Form/FormInput/FormInput.css" scoped />
