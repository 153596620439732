import { inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDeviceStore } from '@/stores/device'
import { useStockTransferStore } from '@/stores/stockTransfer.js'
import { useStock } from '@/composables/useStock.js'
import { MetaDataYup } from '@/utils/validateMetadata.js'
import { string as yupString } from 'yup'
import { formatDateToYYYYMMDD } from '@/utils/dateTime'

export function useStockTransfer() {
  const { t } = useI18n()
  const Sentry = inject('Sentry')
  const cl = inject('cl')
  const clUtils = inject('clUtils')
  const supabase = inject('supabase')
  const deviceStore = useDeviceStore()
  const stockTransferStore = useStockTransferStore()
  const { stockCheckAvailability, getStockLocations } = useStock()

  const currentEmployee = deviceStore.settings.currentEmployee
  const employeeData = {
    eid: currentEmployee.eid || '',
    name: currentEmployee.name ? currentEmployee.name : currentEmployee.email
  }

  const stockTransferQuery = {
    include: [
      'attachments',
      'sku',
      'origin_stock_location.address',
      'destination_stock_location.address',
      'line_item.item',
      'line_item.line_item_options.sku_option',
      'shipment.order.customer',
      'shipment.order.billing_address',
      'shipment.attachments'
    ],
    fields: {
      addresses: ['reference', 'country_code', 'full_name'],
      stock_transfers: [
        'number',
        'completed_at',
        'cancelled_at',
        'reference',
        'reference_origin',
        'quantity',
        'status',
        'origin_stock_location',
        'destination_stock_location',
        'line_item',
        'metadata',
        'shipment',
        'updated_at',
        'created_at',
        'completed_at',
        'in_transit_at',
        'sku',
        'attachments'
      ],
      stock_items: ['stock_location'],
      line_items: ['id', 'reference', 'line_item_options', 'metadata', 'item', 'quantity'],
      line_item_options: ['sku_option', 'metadata'],
      sku: ['code', 'metadata', 'image_url'],
      sku_options: ['metadata', 'reference_origin'],
      stock_locations: ['id', 'name', 'code', 'address'],
      shipments: ['id', 'status', 'order', 'number', 'attachments', 'metadata'],
      orders: ['customer_email', 'number', 'billing_address']
    }
  }

  class StockTransferReorderError extends Error {
    constructor(message) {
      super(message)
      this.name = 'StockTransferReorderError'
    }
  }

  class StateMachine {
    constructor() {
      this.states = ['upcoming', 'picking', 'in_transit', 'complete']
    }

    transition(transfer, targetState) {
      if (!transfer) {
        return
      }
      const { id, status } = transfer
      const currentState = status
      const tasks = []
      if (currentState === targetState) {
        return
      }

      if (['completed', 'cancelled'].includes(currentState)) {
        return
      }

      // if target state is cancel, put it directly to cancel
      if (targetState === 'cancel') {
        this.createTask('cancel', id, tasks)
        return tasks
      }

      let currentIndex = this.states.indexOf(currentState)
      let targetIndex = this.states.indexOf(targetState)

      if (currentIndex === -1 || targetIndex === -1) {
        throw new Error(`Invalid state transition ${currentState} -> ${targetState}`)
      }

      if (currentState !== 'on_hold' && targetState === 'on_hold') {
        this.createTask('on_hold', id, tasks)
        return tasks
      }

      while (currentIndex !== targetIndex) {
        let nextIndex = currentIndex < targetIndex ? currentIndex + 1 : currentIndex - 1
        let nextState = this.states[nextIndex]
        this.createTask(nextState, id, tasks)
        currentIndex = nextIndex
      }

      return tasks
    }

    createTask(targetState, id, tasks) {
      tasks.push({
        resourceType: 'stock_transfers',
        operation: 'update',
        resource: {
          id: id,
          [`_${targetState}`]: true
        },
        onFailure: {
          errorHandler: (error) => {
            Sentry.captureException(error)
          }
        }
      })
    }
  }

  async function stockTransferStatusTransition(transfer, targetState) {
    const stateMachine = new StateMachine()
    const tasks = stateMachine.transition(transfer, targetState)
    let stockTransferUpdated = transfer

    if (!tasks || tasks?.length === 0) {
      return stockTransferUpdated
    }

    let stateName = targetState
    if (targetState === 'complete') {
      stateName = 'completed'
    } else if (targetState === 'cancel') {
      stateName = 'cancelled'
    }

    // add a last task to retrieve the stock transfer to update the metadata
    tasks.push({
      resourceType: 'stock_transfers',
      operation: 'update',
      resource: {
        id: transfer.id
      },
      prepareResource: (res, last) => {
        return {
          ...res,
          metadata: {
            ...last.metadata,
            [`employee_${stateName}`]: employeeData
          }
        }
      },
      params: stockTransferQuery,
      onSuccess: {
        callback: (response) => {
          stockTransferUpdated = response
        }
      }
    })

    await clUtils.executeBatch({
      tasks: tasks,
      options: { haltOnError: true }
    })

    return stockTransferUpdated
  }

  function getSchema() {
    return new MetaDataYup({
      cancel_reason: yupString().matches(
        /^(CANCELLED|MISSING|WRONG_SKU|QUALITY_ISSUE|RETURN|APPLICATION_ERROR)$/
      ),
      cancel_description: yupString(),
      cancel_type: yupString().matches(
        /^(COATING|SCRATCHED|WORKSHOP_DAMAGE|ARRIVED_DAMAGE|COLOUR|ENTRY_ERROR|SUPPLIER_ERROR|SEND_BACK|THROW_AWAY)$/
      ),
      wrongly_delivered_sku: yupString(),
      expected_delivery_date: yupString(),
      create_reason: yupString()
    })
  }

  async function validateMetadata(metadata) {
    const schema = getSchema()
    await schema.validate(metadata)
  }

  /**
   * Always create stock transfer in picking status
   */
  async function stockTransferCreate(
    attributes,
    query = stockTransferQuery,
    serviceHubToPicking = true
  ) {
    if (attributes.metadata) {
      attributes.metadata.employee_created = employeeData
      await validateMetadata(attributes.metadata)
    } else {
      attributes.metadata = {
        employee_created: employeeData
      }
    }

    let stockTransfer = await cl.stock_transfers.create(attributes, query)

    // set to upcoming
    stockTransfer = await stockTransferUpdate({ id: stockTransfer.id, _upcoming: true }, query)
    // set to picking -- only when origin_stock_location starts with 'SH'
    // external stock transfers are only in upcoming status
    if (serviceHubToPicking && stockTransfer.origin_stock_location?.code?.startsWith('SH')) {
      stockTransfer = await stockTransferUpdate({ id: stockTransfer.id, _picking: true }, query)
    }
    return stockTransfer
  }

  async function stockTransferUpdate(
    attributes,
    query = stockTransferQuery,
    metadataCheck = false
  ) {
    const { id } = attributes
    const tasks = []
    let stockTransfer
    if (attributes.metadata && metadataCheck) {
      await validateMetadata(attributes.metadata)
      tasks.push({
        resourceType: 'stock_transfers',
        operation: 'retrieve',
        resource: id
      })
    }

    tasks.push({
      resourceType: 'stock_transfers',
      operation: 'update',
      resource: attributes,
      prepareResource: (res, last) => {
        if (last?.metadata) {
          res.metadata = {
            ...last.metadata,
            ...res.metadata
          }
        }
        return res
      },
      params: query,
      onSuccess: {
        callback: (response) => {
          stockTransfer = response
        }
      },
      onFailure: { errorHandler: (error) => Sentry.captureException(error) }
    })

    await clUtils.executeBatch({
      tasks,
      options: { haltOnError: true }
    })

    return stockTransfer
  }

  async function stockTransferList(query) {
    return await cl.stock_transfers.list({ ...stockTransferQuery, ...query })
  }

  async function stockTransferChangeStatus(id, status) {
    return await stockTransferUpdate(
      {
        id: id,
        [`_${status}`]: true
      },
      stockTransferQuery,
      true
    )
  }

  async function stockTransferComplete(id) {
    const attributes = {
      id: id,
      _complete: true,
      metadata: {
        employee_completed: employeeData
      }
    }
    const transfer = await stockTransferUpdate(attributes, stockTransferQuery, true)
    // if transfer has shipment and the shipment is in status on_hold set employee_picking
    if (transfer.shipment?.id && transfer.shipment?.metadata && transfer.shipment?.status === 'picking') {
      await cl.shipments.update({
        id: transfer.shipment.id,
        metadata: {
          ...transfer.shipment.metadata,
          employee_picking: employeeData
        }
      })
    }

    // now we have to add the badge number
    // get the stock item of the stock transfer
    if (transfer.reference || transfer.reference_origin) {
      try {
        // if we have a shipment, we have to update the stock line item
        if (transfer.line_item?.id) {
          const stockLineItem = await cl.stock_line_items.list({
            filters: {
              line_item_id_eq: transfer.line_item.id
            }
          })

          if (stockLineItem.length === 1) {
            await cl.stock_line_items.update({
              id: stockLineItem[0].id,
              reference: transfer.reference
            })

            return transfer
          }
        } else {
          // in case of a restock, we have to update the stock item
          const stockItems = await cl.stock_items.list({
            filters: {
              sku_code_eq: transfer.sku.code,
              stock_location_code_eq: transfer.destination_stock_location.code
            }
          })

          if (stockItems.length === 1) {
            await cl.stock_items.update({
              id: stockItems[0].id,
              reference: transfer.reference
            })

            return transfer
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        throw new StockTransferReorderError(
          t('stock_transfer.reorder.error.update', { error: error.message })
        )
      }
    } else {
      return transfer
    }
  }

  async function stockTransferHandleRestock(stockTransfer) {
    // fetch the stock item
    const stockItems = await cl.stock_items.list({
      filters: {
        sku_code_eq: stockTransfer.sku.code,
        stock_location_code_eq: stockTransfer.destination_stock_location.code
      }
    })

    if (stockItems.length === 0) {
      throw new StockTransferReorderError(
        t('stock_transfer.restock.error.no_stock_item', { sku: stockTransfer.sku.code })
      )
    }

    // increase the quantity of the stock item
    try {
      // set the stock transfer restocked true
      return await cl.stock_transfers.update(
        {
          id: stockTransfer.id,
          _complete: true,
          metadata: {
            ...stockTransfer.metadata,
            employee_completed: employeeData,
            restocked: true
          }
        },
        stockTransferQuery
      )
    } catch (error) {
      Sentry.captureException(error)
      throw new StockTransferReorderError(
        t('stock_transfer.restock.error.update', { error: error.message })
      )
    }
  }

  async function stockTransferHandleWrongSku(stockTransfer, wronglyDeliveredSku) {
    // if transfer has no metadata field called wrongly_delivered_sku - fetch the stock transfer
    if (!stockTransfer.metadata?.wrongly_delivered_sku) {
      // fetch the stock transfer to make sure it is not already handled
      const stockTransferCheck = await cl.stock_transfers.retrieve(stockTransfer.id)

      if (stockTransferCheck.metadata?.wrong_sku_handled === true) {
        // if already handled, we can skip this step
        return
      }
    }
    // if wrong sku, we have to increase the quantity of the stock item of the given sku
    try {
      const stockItemDestination = await cl.stock_items.list({
        filters: {
          sku_code_eq: wronglyDeliveredSku,
          stock_location_code_eq: stockTransfer.destination_stock_location.code
        }
      })

      const stockItemOrigin = await cl.stock_items.list({
        filters: {
          sku_code_eq: wronglyDeliveredSku,
          stock_location_code_eq: stockTransfer.origin_stock_location.code
        }
      })

      // if no stock item is found, we have to create one
      if (stockItemDestination.length === 0) {
        await cl.stock_items.create({
          sku_code: wronglyDeliveredSku,
          quantity: stockTransfer.quantity,
          stock_location: cl.stock_locations.relationship(
            stockTransfer.destination_stock_location.id
          )
        })
      } else {
        await cl.stock_items.update({
          id: stockItemDestination[0].id,
          quantity: stockItemDestination[0].quantity + stockTransfer.quantity
        })
      }

      // if the stock item of the origin stock location is found, we have to decrease the quantity of the wrongly delivered sku
      if (stockItemOrigin.length === 1) {
        let quantity = stockItemOrigin[0].quantity - stockTransfer.quantity

        if (quantity < 0) {
          quantity = 0
        }
        await cl.stock_items.update({
          id: stockItemOrigin[0].id,
          quantity: quantity
        })
      }

      // now mark the stock transfer that the booking of the wrong sku is done to avoid double bookings
      await cl.stock_transfers.update({
        id: stockTransfer.id,
        metadata: {
          ...stockTransfer.metadata,
          wrong_sku_handled: true
        }
      })
    } catch (error) {
      Sentry.setContext('stockTransferHandleWrongSku', {
        stockTransfer
      })
      Sentry.captureException(error)
    }
  }

  async function stockTransferHandleReorderCancel(
    stockTransfer,
    reorderForm,
    doCancel,
    takeFromStoreStockLocation = false
  ) {
    const reorderAttributes = {
      id: stockTransfer.id
    }

    // Cancel the stock transfer if it is not completed
    if (doCancel) {
      reorderAttributes._cancel = true
    } else {
      reorderAttributes._complete = true
    }

    reorderAttributes.metadata = {
      ...stockTransfer.metadata,
      cancel_reason: reorderForm.cancelReason,
      employee_cancelled: employeeData
    }

    if (takeFromStoreStockLocation) {
      reorderAttributes.metadata = {
        ...reorderAttributes.metadata,
        reordered_from_store: reorderForm.reorderStockLocationCode
      }
    }

    if (reorderForm.wronglyDeliveredSku) {
      reorderAttributes.metadata = {
        ...reorderAttributes.metadata,
        wrongly_delivered_sku: reorderForm.wronglyDeliveredSku
      }
    }

    if (reorderForm.cancelType && reorderForm.cancelType !== '') {
      reorderAttributes.metadata.cancel_type = reorderForm.cancelType
    }

    const attachmentArgs = {
      name: `${stockTransfer.number}_${Date.now()}`,
      url: reorderForm.image,
      reference: stockTransfer.number,
      reference_origin: 'stock-transfer--reorder',
      metadata: {
        cancel_reason: reorderForm.cancelReason,
        cancel_type: reorderForm.cancelType,
        employee: {
          name: deviceStore.settings.currentEmployee.name || '',
          eid: deviceStore.settings.currentEmployee.eid || ''
        }
      },
      attachable: null
    }

    try {
      const stockTransferUpdated = await stockTransferUpdate(reorderAttributes)
      attachmentArgs.attachable = cl.stock_transfers.relationship(stockTransferUpdated.id)
      await cl.attachments.create(attachmentArgs)

      return { stockTransferUpdated, attachmentArgs }
    } catch (error) {
      Sentry.setContext('stockTransferHandleReorderCancel', {
        stockTransfer,
        reorderForm,
        doCancel,
        reorderAttributes
      })

      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('stockTransferHandleReorderCancel')
        return scope
      })

      return {
        stockTransferUpdated: null,
        attachmentArgs: null
      }
    }
  }

  async function stockTransferHandleReorder(stockTransfer, reorderForm, isOutgoing = false) {
    const isRestock = !stockTransfer?.shipment?.id
    let hasStock = false
    const takeFromStoreStockLocation =
      reorderForm.reorderStockLocationCode === stockTransfer.destination_stock_location.code
    const isExternalSupplier = ['lens', 'customMadeFrame'].includes(stockTransfer.sku.metadata.type)

    if (reorderForm.doReorder === 'NO' && reorderForm.cancelType) {
      // special case only report the issue but do not reorder
      const stockTransferUpdated = await stockTransferUpdate({
        id: stockTransfer.id,
        metadata: {
          ...stockTransfer.metadata,
          reported_issue: {
            reason: reorderForm.cancelReason,
            type: reorderForm.cancelType,
            reported_at: new Date().toISOString(),
            employee_reported: employeeData
          }
        }
      })

      // flag it as no reorder - just for notification
      stockTransferUpdated.no_reorder = true

      return { stockTransferUpdated }
    }

    if (isOutgoing) {
      // if outgoing cancel and do not reorder
      const { stockTransferUpdated } = await stockTransferHandleReorderCancel(
        stockTransfer,
        reorderForm,
        true
      )
      return { stockTransferUpdated }
    }

    if (
      reorderForm.cancelReason === 'WRONG_SKU' &&
      reorderForm.wronglyDeliveredSku &&
      stockTransfer.metadata?.wrong_sku_handled !== true
    ) {
      // fix the stock item of the wrongly delivered sku first
      await stockTransferHandleWrongSku(stockTransfer, reorderForm.wronglyDeliveredSku)
    }

    if (!isExternalSupplier && reorderForm.doReorder !== 'NO') {
      await getStockLocations(stockTransfer.sku.code, reorderForm.reorderStockLocationCode)
      // Before reordering, check stock availability
      hasStock = await stockCheckAvailability(
        stockTransfer.sku.code,
        reorderForm.reorderStockLocationCode
      )
    } else {
      hasStock = true
    }

    if (!hasStock && isRestock && reorderForm.cancelReason === 'MISSING') {
      const transfer = await stockTransferUpdate({
        id: stockTransfer.id,
        _cancel: true,
        metadata: {
          ...stockTransfer.metadata,
          cancel_reason: reorderForm.cancelReason,
          employee_cancelled: employeeData
        }
      })
      return {
        stockTransferUpdated: {
          ...transfer,
          error: t('stock_transfer.reorder.error.no_stock', { sku: stockTransfer.sku.code })
        }
      }
    } else if (!hasStock) {
      throw new StockTransferReorderError(
        t('stock_transfer.reorder.error.no_stock', { sku: stockTransfer.sku.code })
      )
    }

    // doCancel if the reason is not 'Quality Issue' and it is not yet completed or cancelled
    const doCancel =
      reorderForm.cancelReason !== 'QUALITY_ISSUE' &&
      stockTransfer.status !== 'completed' &&
      stockTransfer.status !== 'cancelled'

    const { stockTransferUpdated, attachmentArgs } = await stockTransferHandleReorderCancel(
      stockTransfer,
      reorderForm,
      doCancel,
      takeFromStoreStockLocation
    )

    if (!stockTransferUpdated) {
      throw new StockTransferReorderError(t('stock_transfer.reorder.error.update'))
    }

    let stockTransferReordered
    // Let's do stock movements based on the reason.
    if (
      stockTransferUpdated.status === 'completed' &&
      !stockTransferUpdated.metadata.quantity_decreased
    ) {
      let stockItemToDecrease
      try {
        const reorderQuantity = stockTransferUpdated.quantity
        if (!isRestock) {
          // in case of a shipment, we have to fetch the stock line item of the shipment
          // Now we have to correct the stock line item of the shipment if the stock transfer is completed
          const fulfillment = await cl.shipments.retrieve(stockTransferUpdated.shipment.id, {
            include: ['stock_line_items.stock_item', 'stock_line_items.line_item'],
            fields: {
              shipments: ['stock_line_items'],
              stock_line_items: ['stock_item', 'line_item']
            }
          })

          // Fetch the stock line item that is related to the stock transfer
          const stockLineItem = fulfillment?.stock_line_items?.find(
            (stockLineItem) => stockLineItem.line_item?.id === stockTransferUpdated?.line_item?.id
          )

          if (stockLineItem) {
            stockItemToDecrease = stockLineItem?.stock_item

            // release the stock line item reservation
            await cl.stock_line_items.update(
              {
                id: stockLineItem.id,
                _release_stock: true
              },
              {
                include: ['stock_item']
              }
            )

            // delete the stock line item
            await cl.stock_line_items.delete(stockLineItem.id)
          }
        } else if (isRestock) {
          // in case of a restock, we have to fetch the stock item of the stock transfer
          const stockItems = await cl.stock_items.list({
            filters: {
              sku_code_eq: stockTransferUpdated.sku.code,
              stock_location_code_eq: stockTransferUpdated.destination_stock_location.code
            }
          })

          if (stockItems.length === 0) {
            throw new Error(t('stock_transfer.reorder.error.no_stock_item'))
          } else {
            stockItemToDecrease = stockItems[0]
          }
        }

        if (stockItemToDecrease?.quantity) {
          const newStockItemQuantity = stockItemToDecrease.quantity - reorderQuantity
          if (newStockItemQuantity >= 0) {
            // decrease the quantity of the stock item of the given sku in the destination stock location
            await cl.stock_items.update({
              id: stockItemToDecrease.id,
              quantity: newStockItemQuantity
            })
          }

          // set a flag that indicates that quantity was decreased
          await cl.stock_transfers.update({
            id: stockTransferUpdated.id,
            metadata: {
              ...stockTransferUpdated.metadata,
              quantity_decreased: true
            }
          })
        }
      } catch (error) {
        Sentry.captureException(error)
        throw new StockTransferReorderError(
          t('stock_transfer.reorder.error.stock', { error: error.message })
        )
      }
    }

    /**
     * Reorder the stock transfer
     * if it has local stock, we have to create a new stock line item
     * if it has no local stock, we have to create a new stock transfer
     */

    // if reorderForm.doReorder === 'NO' we have to return the stockTransferUpdated and skip the reorder
    if (reorderForm.doReorder === 'NO') {
      // flag it as no reorder - just for notification
      stockTransferUpdated.no_reorder = true
      return { stockTransferUpdated }
    }

    try {
      let stockItem
      if (isExternalSupplier) {
        stockItem = {
          stock_location: {
            id: stockTransferUpdated.origin_stock_location.id
          }
        }
      } else {
        const stockItems = await cl.stock_items.list({
          filters: {
            sku_code_eq: stockTransferUpdated.sku.code,
            stock_location_code_eq: reorderForm.reorderStockLocationCode
          },
          include: ['stock_location']
        })

        stockItem = stockItems[0]
      }
      if (takeFromStoreStockLocation) {
        // create a new stock line item from the local warehouse
        const stockLineItem = await cl.stock_line_items.create({
          sku_code: stockTransferUpdated.sku.code,
          quantity: stockTransferUpdated.quantity,
          shipment: cl.shipments.relationship(stockTransferUpdated.shipment.id),
          line_item: cl.line_items.relationship(stockTransferUpdated.line_item.id),
          stock_item: cl.stock_items.relationship(stockItem.id),
          metadata: {
            cancel_reason: reorderForm.cancelReason,
            cancel_type: reorderForm.cancelType,
            employee_reordered: employeeData
          }
        })

        stockTransferReordered = await cl.stock_line_items.update({
          id: stockLineItem.id,
          _reserve_stock: true
        })
      } else {
        // Now we have to create a reorder stockTransfer
        const attributes = {
          sku_code: stockTransferUpdated.sku.code,
          quantity: stockTransferUpdated.quantity,
          sku: cl.skus.relationship(stockTransferUpdated.sku.id),
          origin_stock_location: cl.stock_locations.relationship(stockItem.stock_location.id),
          destination_stock_location: cl.stock_locations.relationship(
            stockTransferUpdated.destination_stock_location.id
          )
        }

        if (stockTransferUpdated.line_item?.id) {
          attributes.line_item = cl.line_items.relationship(stockTransferUpdated.line_item.id)
        }

        if (stockTransferUpdated.shipment?.id) {
          attributes.shipment = cl.shipments.relationship(stockTransferUpdated.shipment.id)
        }
        stockTransferReordered = await stockTransferCreate(attributes)
        attachmentArgs.attachable = cl.stock_transfers.relationship(stockTransferReordered.id)
        attachmentArgs.metadata = {
          ...attachmentArgs.metadata,
          original_stock_transfer: stockTransferUpdated.number
        }
        await cl.attachments.create(attachmentArgs)

        // now set it to _upcoming
        const reorderAttributes = {
          id: stockTransferReordered.id,
          _upcoming: true
        }
        stockTransferReordered = await stockTransferUpdate(reorderAttributes)
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      // Throw an error that indicates that the stockTransfer could not be created
      throw new StockTransferReorderError(
        t('stock_transfer.reorder.error.create', { error: error.message })
      )
    }

    // check if both stock transfers are created
    if (!stockTransferUpdated || !stockTransferReordered) {
      throw new StockTransferReorderError(
        t('stock_transfer.reorder.error.create', { error: 'Stock transfer not created' })
      )
    }

    // if all went well, we have to put the shipment back on hold - if not already done
    try {
      if (
        stockTransferUpdated?.shipment?.id &&
        stockTransferUpdated?.shipment?.status !== 'on_hold' &&
        stockTransferReordered?.status &&
        !['completed', 'cancelled'].includes(stockTransferReordered?.status)
      ) {
        await cl.shipments.update({
          id: stockTransferUpdated.shipment.id,
          _on_hold: true
        })
      } else if (stockTransferUpdated?.shipment?.id) {
        // get all stock transfers from shipment
        const shipment = await cl.shipments.retrieve(stockTransferUpdated.shipment.id, {
          id: stockTransferUpdated.shipment.id,
          include: ['stock_transfers']
        })

        // check if all stock transfers are completed or cancelled
        const isStockTransferCompleted = shipment.stock_transfers.every(
          (stockTransfer) =>
            stockTransfer.status === 'completed' || stockTransfer.status === 'cancelled'
        )

        if (isStockTransferCompleted && shipment.status === 'on_hold') {
          // if all stock transfers are completed, update the shipment status to completed
          await cl.shipments.update({
            id: stockTransferUpdated.shipment.id,
            _picking: true
          })
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      throw new StockTransferReorderError(error.message)
    }

    return {
      stockTransferUpdated,
      stockTransferReordered
    }
  }

  function stockTransferAvailableActions(
    stockTransfer,
    fulfillmentStatus = '',
    restrictions = {
      showOpenFulfillmentButton: false,
      showOpenShipmentButton: false,
      canModify: false
    }
  ) {
    const { showFulfillmentOpenButton, showOpenShipmentButton, canModify } = restrictions
    // If in_transit we can confirm and reorder
    // If completed we can only reorder
    // if the stock transfer has the metadata employee_cancelled, no actions are available
    let actions = ['confirm', 'reorder', 'open_fulfillment', 'open_shipment']

    if (!showFulfillmentOpenButton) {
      // remove the open_fulfillment action
      actions = actions.filter((action) => action !== 'open_fulfillment')
    }

    if (!showOpenShipmentButton) {
      // remove the open_shipment action
      actions = actions.filter((action) => action !== 'open_shipment')
    }

    if (!canModify) {
      // remove the confirm action
      actions = actions.filter((action) => action !== 'confirm')
    }

    if (stockTransfer.metadata.employee_cancelled) {
      return []
    } else if (stockTransfer.status === 'in_transit') {
      return actions
    } else if (
      ['ready_to_pickup', 'ready_to_ship', 'shipped', 'picked_up'].includes(fulfillmentStatus)
    ) {
      return []
    } else if (stockTransfer.status === 'completed') {
      return actions.filter((action) => action !== 'confirm')
    } else if (
      stockTransfer.status === 'cancelled' &&
      stockTransfer.metadata?.cancel_reason !== 'CANCELLED'
    ) {
      // If the stock transfer is cancelled, but has no metadata cancel_reason, we need to confirm it
      return ['confirm_cancel', 'open_fulfillment']
    } else {
      return []
    }
  }

  async function stockTransferCount(query) {
    return await cl.stock_transfers.count(query)
  }

  /**
   * - Items form a third party supplier can be cancelled until they are sent to the supplier.
   * - Items from the SH can be cancelled until they are in transit
   * - Items from the WH can not be cancelled -> restock
   */
  function stockTransferCanBeCancelled(stockTransfer) {
    const isLensOrCustomMadeFrame = ['lens', 'customMadeFrame'].includes(
      stockTransfer.line_item?.metadata?.type
    )

    if (isLensOrCustomMadeFrame && ['draft', 'upcoming'].includes(stockTransfer.status)) {
      // means the order is not sent supplier
      return true
    } else if (isLensOrCustomMadeFrame) {
      return false
    }

    return (
      stockTransfer.origin_stock_location.code !== 'WH' &&
      stockTransfer.status !== 'cancelled' &&
      stockTransfer.status !== 'completed' &&
      stockTransfer.status !== 'in_transit'
    )
  }

  function stockTransferInStatusSince(stockTransfer) {
    const date = new Date(stockTransfer.updated_at)
    const currentDate = new Date()
    const timeDiff = currentDate.getTime() - date.getTime()
    return timeDiff
  }

  function stockTransferInStatusSinceDays(stockTransfer) {
    const timeDiff = stockTransferInStatusSince(stockTransfer)
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  }

  function stockTransferCheckOverdue(stockTransfer) {
    // 3 days in milliseconds
    const overDueTime = 3 * 24 * 60 * 60 * 1000
    const timeDiff = stockTransferInStatusSince(stockTransfer)
    return timeDiff > overDueTime
  }

  async function stockTransferSetDetails(transfer, loadData = false) {
    let stockTransfer = transfer
    if (loadData) {
      stockTransfer = await cl.stock_transfers.retrieve(transfer.id, stockTransferQuery)
    }
    stockTransferStore.unsetStockTransfer()
    stockTransferStore.setStockTransfer(stockTransfer)
  }

  async function stockTransferFetchLensSupplierHistory(stockTransferNumber) {
    const { data, error } = await supabase.rpc('get_stock_transfer_history', {
      stock_transfer_num: stockTransferNumber
    })

    if (error) {
      Sentry.captureException(error)
      return []
    } else {
      return data
    }
  }

  function stockTransferOverviewQuery(props, parcelKey = false) {
    const query = {
      filters: {
        status_in: props.status.join(',')
      },
      include: stockTransferQuery.include,
      fields: stockTransferQuery.fields,
      sort: {
        number: 'asc'
      },
      pageSize: 25
    }

    if (props.originStockLocation) {
      query.filters.origin_stock_location_code_eq = props.originStockLocation
      query.filters.destination_stock_location_code_not_eq = 'LEGACY_WAREHOUSE'
    } else if (props.destinationStockLocation) {
      query.filters.destination_stock_location_code_eq = props.destinationStockLocation
    }

    if (props.additionalFilters) {
      query.filters = { ...query.filters, ...props.additionalFilters }
    }

    if (parcelKey) {
      // only query for reference_origin_eq
      query.filters = {
        reference_origin_eq: parcelKey
      }
    }

    return query
  }

  async function stockTransferGetDailyClosedIncoming(query) {
    // minus one day
    const yesterday = new Date()
    const today = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const yesterdayDate = formatDateToYYYYMMDD(yesterday)
    const todayDate = formatDateToYYYYMMDD(today)

    const dailyQuery = {
      ...query,
      filters: {
        ...query.filters,
        status_in: 'completed,cancelled',
        completed_at_or_cancelled_at_gteq: `${yesterdayDate}T00:00:00Z`,
        completed_at_or_cancelled_at_lteq: `${todayDate}T23:59:59Z`
      }
    }

    return await clUtils.retrieveAll('stock_transfers', dailyQuery)
  }

  function stockTransferComputedParcelStatus(transfers) {
    // check if on item has status in transit
    const inTransit = transfers.some((item) => item.status === 'in_transit')
    let completed
    let cancelled
    if (!inTransit) {
      completed = transfers.every((item) => item.status === 'completed')
      cancelled = transfers.every((item) => item.status === 'cancelled')
    }

    return inTransit
      ? 'in_transit'
      : completed
        ? 'completed'
        : cancelled
          ? 'cancelled'
          : 'in_transit'
  }

  function stockTransferGetQuery() {
    return stockTransferQuery
  }

  /**
   * if transfer comes form MATERIALISE and the reference_origin starts with 'UPS:' it can not be confirmed by the store.
   * in that case the transfer is on the way to the WH
   * @param {*} stockTransfer
   * @returns boolean
   */
  function stockTransferIsMaterializeToWH(stockTransfer) {
    if (stockTransfer?.origin_stock_location?.code === 'MATERIALISE') {
      const referenceOrigin = stockTransfer?.reference_origin
      if (referenceOrigin?.startsWith('UPS:')) {
        return true
      }
    }

    return false
  }

  return {
    stockTransferStatusTransition,
    stockTransferAvailableActions,
    stockTransferCreate,
    stockTransferUpdate,
    stockTransferList,
    stockTransferComplete,
    stockTransferChangeStatus,
    stockTransferHandleReorder,
    stockTransferCount,
    stockTransferHandleRestock,
    stockTransferCanBeCancelled,
    stockTransferCheckOverdue,
    stockTransferInStatusSinceDays,
    stockTransferSetDetails,
    stockTransferFetchLensSupplierHistory,
    stockTransferOverviewQuery,
    stockTransferGetDailyClosedIncoming,
    stockTransferComputedParcelStatus,
    stockTransferGetQuery,
    stockTransferIsMaterializeToWH
  }
}
