import { object as yupObject, string as yupString } from 'yup'
import { useI18n } from 'vue-i18n'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import { toValue } from 'vue'

export function useAddress() {
  const { t } = useI18n()

  function getAddressValidationSchema(addressForm) {
    return yupObject().shape({
      firstName: yupString()
        .required(t('validation.required', { fieldName: t('customer.form.label.first_name') }))
        .matches(/^[^0-9^;:+*=?.$!]*$/, t('validation.no_numbers_allowed')),
      lastName: yupString()
        .required(t('validation.required', { fieldName: t('customer.form.label.last_name') }))
        .matches(/^[^0-9^;:+*=?.$!]*$/, t('validation.no_numbers_allowed')),
      street: yupString()
        .required(t('validation.required', { fieldName: t('customer.form.label.street') }))
        .matches(/^(?=.*\s\d)(?=.*[A-Za-z]).{3,}/, t('validation.invalid_street')),
      postalCode: yupString()
        .required(t('validation.required', { fieldName: t('customer.form.label.street') }))
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .test('is-postal-code-valid', t('validation.invalid_postal_code'), function (value) {
          value = value.trim()
          if (value) {
            const countryCode = toValue(addressForm)?.countryCode
            if (countryCode && postcodeValidatorExistsForCountry(countryCode)) {
              return postcodeValidator(value, countryCode)
            } else {
              return /\d{2}/.test(value)
            }
          }
          return true
        }),
      city: yupString()
        .required(t('validation.required', { fieldName: t('customer.form.label.city') }))
        .test(
          'is-city-valid',
          t('validation.required', { fieldName: 'customer.form.label.city' }),
          function (value) {
            return !value || (value && value.trim().length > 0)
          }
        )
    })
  }

  return {
    getAddressValidationSchema
  }
}
