import { inject } from 'vue'
import { fetchEdgeFunctionError } from '@/utils/supabase'
import { useZap } from '@/composables/useZap'
import { useNotification } from '@/composables/useNotification.js'
import { useDeviceStore } from '@/stores/device.js'
import { useI18n } from 'vue-i18n'

export function usePayment() {
  const Sentry = inject('Sentry')
  const supabase = inject('supabase')
  const cl = inject('cl')
  const zap = useZap()
  const { showNotification } = useNotification()
  const deviceStore = useDeviceStore()
  const { t } = useI18n()
  const functionName = 'adyen-terminal-actions'

  async function logSupabaseFunctionError(data, error) {
    const errorMessage = await fetchEdgeFunctionError(error)

    if (errorMessage) {
      Sentry.setContext('error', errorMessage)
      Sentry.setContext('data', data)
    }

    return errorMessage
  }

  /**
   * Aborts a terminal transaction
   *
   * Supabase will send an abort request to the Adyen Terminal API.
   * The Terminal will then abort the payment process.
   */
  async function terminalTransactionAbort(captureObject, terminalId) {
    await supabase.functions.invoke(
      `${functionName}?action=abort&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body: {
          ...captureObject,
          reference: terminalId
        },
        region: 'eu-central-1'
      }
    )
  }

  /**
   * This call checks the status of the terminal transaction.
   *
   * Supabase will send a verify request to the Adyen Terminal API.
   * The it updates the captures object via CL Webhook with the status of the terminal transaction.
   */
  async function terminalTransactionVerify(transactionObject, terminalId) {
    const type = transactionObject.type
    const { data, error } = await supabase.functions.invoke(
      `${functionName}?action=verify&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body: {
          ...transactionObject,
          reference: terminalId
        },
        region: 'eu-central-1'
      }
    )

    const errorMessage = await fetchEdgeFunctionError(error)

    if (errorMessage) {
      Sentry.setContext('error', errorMessage)
      Sentry.setContext('data', data)
    }

    // fetch the captures object from commercelayer
    if (data?.success) {
      if (type === 'captures') {
        const capture = await cl.captures.retrieve(transactionObject.id)

        // if captures is not succeeded, but it is in Adyen forward the captures object
        await cl.captures.update({
          id: capture.id,
          _forward: true,
          metadata: {
            ...capture.metadata,
            message: data?.message
          }
        })
      } else if (type === 'refunds') {
        const refund = await cl.refunds.retrieve(transactionObject.id)

        // if refunds is not succeeded, but it is in Adyen forward the refunds object
        await cl.refunds.update({
          id: refund.id,
          _forward: true,
          metadata: {
            ...refund.metadata,
            message: data?.message
          }
        })
      }
    }

    return { data, error: errorMessage }
  }

  /**
   * Unreferenced refund bypassing commercelayer payment Gateway
   */
  async function terminalTransactionRefund(refundData) {
    const { currencyCode, amountFloat, terminalId, transactionId, saleId } = refundData
    const { data, error } = await supabase.functions.invoke(
      `${functionName}?action=unreferenced-refund&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body: {
          amount_float: amountFloat,
          currency_code: currencyCode,
          terminal_id: terminalId,
          transaction_id: transactionId,
          sale_id: saleId
        },
        region: 'eu-central-1'
      }
    )

    const errorMessage = await logSupabaseFunctionError(data, error)

    return { data, error: errorMessage }
  }

  async function terminalsList(store) {
    const { data, error } = await supabase.functions.invoke(
      `${functionName}?action=terminals&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body: {
          store: store
        },
        region: 'eu-central-1'
      }
    )

    const errorMessage = await logSupabaseFunctionError(data, error)

    const terminals = data?.data || []

    return { data: terminals, error: errorMessage }
  }

  async function terminalTest(terminalId) {
    const body = {
      store: deviceStore.settings.warehouse,
      terminalID: terminalId,
      currencyCode: deviceStore.settings.currency
    }

    const { data, error } = await supabase.functions.invoke(
      `${functionName}?action=terminal-test&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body,
        region: 'eu-central-1'
      }
    )

    const errorMessage = await logSupabaseFunctionError(data, error)

    return {
      ...data,
      error: errorMessage
    }
  }

  async function terminalDiagnosis(terminalId) {
    const body = {
      store: deviceStore.settings.warehouse,
      terminalID: terminalId
    }

    const { data, error } = await supabase.functions.invoke(
      `${functionName}?action=diagnosis&env=${import.meta.env.VITE_WEB_ENV}`,
      {
        body,
        region: 'eu-central-1'
      }
    )

    const errorMessage = await logSupabaseFunctionError(data, error)

    // response example
    // {
    //   "SaleToPOIResponse": {
    //     "DiagnosisResponse": {
    //       "HostStatus": [
    //         {
    //           "AcquirerID": "0",
    //           "IsReachableFlag": true
    //         }
    //       ],
    //       "POIStatus": {
    //         "CommunicationOKFlag": true,
    //         "GlobalStatus": "OK",
    //         "PrinterStatus": "NoPaper"
    //       },
    //       "Response": {
    //         "AdditionalResponse": "TerminalAPiConnectionTest=eyJUZXJtaW5hbEFwaUVuYWJsZWQiOiJ0cnVlIiwiV2Vic29ja2V0Q29ubmVjdGVkIjoidHJ1ZSIsIldlYnNvY2tldEVuYWJsZWQiOiJ0cnVlIn0%3d&batteryLevel=100%25&firmwareVersion=adyen_v1_108p2&merchantAccount=VIUDECOM&networkProfile=W3siQWRkcmVzcyI6IjEwLjEwLjEyLjI1MCIsIklmYWNlIjoid2xhbjAifSx7IkFkZHJlc3MiOiIxMC4yMy4yMDIuMjE3IiwiSWZhY2UiOiJybW5ldF9kYXRhMiJ9XQ%3d%3d&storeId=MUC1&terminalId=S1F2-000158215131750&unconfirmedBatchCount=0&warnings=At%20SaleToPOIRequest.MessageHeader%2c%20field%20%27MessageClassQualifier%27%3a%20Unexpected",
    //         "Result": "Success"
    //       }
    //     },
    //     "MessageHeader": {
    //       "MessageCategory": "Diagnosis",
    //       "MessageClass": "Service",
    //       "MessageType": "Response",
    //       "POIID": "S1F2-000158215131750",
    //       "ProtocolVersion": "3.0",
    //       "SaleID": "MUC1",
    //       "ServiceID": "e162f5d099"
    //     }
    //   }
    // }

    // Response.AdditionalResponse: a string of key-value pairs separated by & that includes:

    // batteryLevel: the battery charge level as a percentage of fully charged (only included for
    // battery-powered terminals).
    // unconfirmedBatchCount: number of payments that the terminal hasn't yet sent for completion to our platform, and thus can't be settled.
    // firmwareVersion: software version that the terminal is on.
    // networkProfile: a Base64 string. Decode this to see the IP address of the terminal and other network data.
    // merchantAccount: merchant account that the diagnosed payment terminal is assigned to.
    // terminalId: POIID of the diagnosed payment terminal, in the format [device model]-[serial number].
    // storeId: store that the diagnosed payment terminal is assigned to.
    // tamperStatus: the value ARS_TRIGGERED indicates that anti-removal switches (ARS) have been
    // triggered. This means you must stop using the terminal immediately. Only applies to UX300 and UX410
    // terminals.

    // transform additional response string to object
    const additionalResponse =
      data?.SaleToPOIResponse?.DiagnosisResponse?.Response?.AdditionalResponse
    const additionalResponseObject = additionalResponse
      ?.split('&')
      .map((item) => item.split('='))
      .reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})

    let status

    if (data?.SaleToPOIResponse?.DiagnosisResponse?.Response?.Result === 'Success') {
      status = 'ok'
    } else if (data?.SaleToPOIResponse?.DiagnosisResponse?.Response?.Result) {
      status = 'warning'
    } else {
      status = 'error'
    }
    const rejectError = data?.SaleToPOIRequest?.EventNotification?.EventDetails

    let errorCondition = 'none'
    if (rejectError) {
      errorCondition = rejectError.substring(8)?.replace(/\+/g, ' ')
    } else {
      errorCondition = data?.SaleToPOIResponse?.DiagnosisResponse?.Response?.ErrorCondition
    }

    // See https://docs.adyen.com/point-of-sale/diagnostics/request-diagnosis/#diagnosis-request
    const terminalStatus = {
      poiStatus: {
        cloudStatus: data?.SaleToPOIResponse?.DiagnosisResponse?.HostStatus?.[0]?.IsReachableFlag,
        communication: data?.SaleToPOIResponse?.DiagnosisResponse?.POIStatus?.CommunicationOKFlag,
        globalStatus: data?.SaleToPOIResponse?.DiagnosisResponse?.POIStatus?.GlobalStatus,
        printerStatus: data?.SaleToPOIResponse?.DiagnosisResponse?.POIStatus?.PrinterStatus
      },
      additionalResponse: additionalResponseObject,
      status: status,
      errorCondition: errorCondition
    }

    return {
      terminalStatus,
      error: errorMessage
    }
  }

  function fetchPaymentLinkData(orderData) {
    return orderData?.attachments.filter(
      (attachment) => attachment.reference_origin === 'payment--adyen-payment-link'
    )
  }

  function paymentLinkExpired(paymentLink) {
    const validUntil = new Date(paymentLink.metadata?.valid_until)
      const now = new Date()
      if (validUntil < now) {
        return true
      }

      return false
  }

  function getValidPaymentLinks(orderData, captures = null) {
    const allLinks = fetchPaymentLinkData(orderData)
    const orderPaymentLinks = allLinks?.filter(
      (attachment) =>
        attachment.metadata?.status === 'active'
    )

    // sort the payment links by updated_at in descending order
    orderPaymentLinks.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

    return orderPaymentLinks?.reduce((acc, link) => {
      if (paymentLinkExpired(link)) {
        // skip expired payment links
        return acc
      }

      // find a captures with the metadata.payment_origin === 'ADYEN_LINK' and success === true and amount === link.link_metadata.amount
      const capture = captures?.find(
        (capture) =>
          capture.metadata.payment_method === 'PAYMENT_LINK' &&
          capture.succeeded &&
          capture.amount_cents === Number(link.metadata?.capture_amount_cents)
      )

      if (capture) {
        return acc
      }

      return [...acc, link]
    }, [])
  }

  function getExpiredPaymentLinks(orderData) {
    const allLinks = fetchPaymentLinkData(orderData)
    
    return allLinks?.filter(
      (attachment) => paymentLinkExpired(attachment)
    )
  }



  function getOpenPaymentLinkForOrderDetail(order, openAmount) {
    // we don't have captures in order object yet
    const validPaymentLinks = getValidPaymentLinks(order)

    // only fetch links where the metadata.capture_amount_cents is equal or less than the open amount
    const openPaymentLinks = validPaymentLinks.filter(
      (link) => Number(link.metadata?.capture_amount_cents) <= openAmount
    )

    if (openPaymentLinks.length === 0) {
      return null
    }

    // lets return the first link that is valid
    return openPaymentLinks[0]
  }

  async function getOpenPaymentLinks(order) {
    let orderData = order
    if (!order.attachments || !order.captures) {
      orderData = await cl.orders.retrieve(order.id, { include: ['attachments', 'captures'] })
    }

    return getValidPaymentLinks(orderData, orderData.captures)
  }

  async function payByPaymentLink(email, orderNumber, amountCents) {
    await zap.triggerZap(
      'payment_link',
      {
        customer_email: email,
        order_number: orderNumber,
        capture_amount_cents: amountCents
      },
      { notifyFailure: false }
    )

    showNotification({
      message: t('order.payment_link_sent', { to: email }),
      variant: 'success'
    })
  }

  async function disablePaymentLink(paymentLink) {
    if (!paymentLink?.id) {
      return
    }

    try {
      // disable payment link
      await cl.attachments.update({
        id: paymentLink.id,
        metadata: {
          ...paymentLink.metadata,
          status: 'cancelled'
        }
      })

      await zap.triggerZap(
        'payment_link_disable',
        {
          payment_link_id: paymentLink.reference
        },
        { notifyFailure: false }
      )
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setContext('paymentLink', paymentLink)
        scope.setTransactionName('disablePaymentLink')
        return scope
      })
    }
  }

  /**
   * Returns a credit card payment that needs to be verified.
   */
  function getPaymentToVerify(transactions) {
    const capture = transactions?.find(
      (capture) =>
        capture.metadata.payment_method === 'CREDIT_CARD' &&
        !capture.succeeded &&
        capture.message === 'Accepted'
    )

    if (capture) {
      // find authoritians with the metadata.payment_origin === 'ADYEN' and success === true
      const authorizations = transactions.find(
        (authorization) => authorization.type === 'authorizations'
      )

      if (authorizations) {
        return {
          ...capture,
          reference: authorizations.reference
        }
      }
    }

    return null
  }

  function validatePaymentLinkData(billingAddress, ShippingAddress) {
    // Both address do need to be present with city, country_code, zip_code and line_1
    if (!billingAddress || !ShippingAddress) {
      return false
    }

    const requiredFields = ['city', 'country_code', 'zip_code', 'line_1']

    // check if all required fields are present and minimum length 2
    const isValidBillingAddress = requiredFields.every(
      (field) => billingAddress[field] && billingAddress[field].length > 1
    )

    const isValidShippingAddress = requiredFields.every(
      (field) => ShippingAddress[field] && ShippingAddress[field].length > 1
    )

    const isValid = isValidBillingAddress && isValidShippingAddress

    if (!isValid) {
      showNotification({
        message: t('order.payment_link_address_error'),
        variant: 'danger'
      })

      return false
    }

    return true
  }

  return {
    terminalTransactionAbort,
    terminalTransactionVerify,
    terminalTransactionRefund,
    terminalsList,
    terminalTest,
    terminalDiagnosis,
    payByPaymentLink,
    getOpenPaymentLinks,
    getOpenPaymentLinkForOrderDetail,
    disablePaymentLink,
    getPaymentToVerify,
    validatePaymentLinkData,
    getExpiredPaymentLinks
  }
}
