import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useDeviceStore } from '@/stores/device.js'
import { useUiStore } from '@/stores/ui.js'
import { inject, computed } from 'vue'
import { useNotification } from '@/composables/useNotification.js'
import { getErrorMessage } from '@/utils/error.js'
import { useCart } from '@/composables/useCart.js'
export function useShipment() {
  const cartStore = useCartStore()
  const customerStore = useCustomerStore()
  const deviceStore = useDeviceStore()
  const uiStore = useUiStore()
  const { cartUpdate } = useCart()
  const { showNotification } = useNotification()
  const cl = inject('cl')
  const Sentry = inject('Sentry')

  async function setShippingMethod(shippingMethodReference, cartId) {
    await cl.orders.update({
      id: cartId,
      metadata: {
        shipping_method: shippingMethodReference
      }
    })
    cartStore.hasValidShippingMethod = true
  }

  async function setClShippingMethod(shippingMethodReference, cartId) {
    try {
      const order = await cl.orders.retrieve(cartId, {
        include: ['shipments.available_shipping_methods']
      })
      const shippingMethod = order?.shipments[0]?.available_shipping_methods.find(
        (shippingMethod) => shippingMethod.reference === shippingMethodReference
      )

      if (!shippingMethod) {
        cartStore.hasValidShippingMethod = false
        return
      }

      cartStore.hasValidShippingMethod = true

      const data = {
        id: order.shipments[0].id,
        shipping_method: cl.shipping_methods.relationship(shippingMethod.id)
      }

      await cl.shipments.update(data)
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function setBillingAddress(billingAddressId) {
    try {
      await cartUpdate({
        id: customerStore.customer.currentCart.id,
        _billing_address_clone_id: billingAddressId
      })
      cartStore
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function setShippingAddressAndMethod(shippingMethodReference, shippingAddressId) {
    const storeAddressId = computed(() => deviceStore.getStoreAddressId)

    if (!shippingAddressId) {
      showNotification({
        message: 'setShippingAddressAndMethod: shippingAddressId is required',
        variant: 'danger'
      })
    }

    try {
      const cartMeta = customerStore.customer.currentCart.metadata

      if (shippingMethodReference === 'SHIPPING') {
        delete cartMeta.pickup_location
      }

      const data = {
        id: customerStore.customer.currentCart.id,
        ...(storeAddressId.value !== shippingAddressId && {
          _shipping_address_clone_id: shippingAddressId
        }),
        ...(storeAddressId.value === shippingAddressId && {
          shipping_address: cl.addresses.relationship(shippingAddressId)
        }),
        metadata: {
          ...cartMeta,
          ...(shippingMethodReference === 'PICKUP' && {
            pickup_location: cartStore.pickupLocation
          }),
          delivery_method: shippingMethodReference
        }
      }

      await cartUpdate(data)
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function fetchAvailableAllShippingMethods() {
    return await cl.shipping_methods.list({
      filters: {
        disabled_at_null: true
      },
      include: ['shipping_zone'],
      fields: {
        shipping_methods: ['reference', 'shipping_zone', 'currency_code'],
        shipping_zones: ['name', 'country_code_regex', 'not_country_code_regex']
      }
    })
  }

  /**
   * Get available shipping methods for the current order country code.
   * All available shipping methods are loaded on store initialization.
   *
   * @param {*} shippingMethod
   * @param {*} countryCode
   * @returns
   */
  function getAvailableShippingMethods(shippingMethod = null, countryCode = null) {
    const currentOrderCountryCode = uiStore.currentOrderCountryCode
      ? uiStore.currentOrderCountryCode.toUpperCase()
      : deviceStore.settings.country.toUpperCase()
    countryCode = countryCode ? countryCode : currentOrderCountryCode
    // filter available shipping methods form device store by customerStore.customer.currentCart.currency_code
    const availableShippingMethods =
      deviceStore.settings.availableShippingMethods?.filter((method) =>
        method.shipping_zone.country_code_regex.includes(countryCode)
      ) || []

    if (shippingMethod) {
      return availableShippingMethods.filter((method) => method.reference === shippingMethod)
    }

    return availableShippingMethods
  }

  /**
   * Get the shipping method by reference.
   * You can also specify the country code of the order and the available shipping methods.
   *
   * @param {*} shippingMethodReference
   * @param {*} countryCodeOrder
   * @param {*} availableShippingMethods
   * @returns boolean
   */
  function getShippingMethod(
    shippingMethodReference = 'SHIPPING',
    countryCodeOrder = null,
    availableShippingMethods = []
  ) {
    if (availableShippingMethods.length === 0) {
      availableShippingMethods = getAvailableShippingMethods(
        shippingMethodReference,
        countryCodeOrder
      )
    }

    return availableShippingMethods.find((method) => method.reference === shippingMethodReference)
  }

  /**
   * Check if the given country code is in the shipping zone.
   * You can also specify the shipping method reference, the country code of the order and the available shipping methods. If not specified, the default values are used.
   *
   * @param {*} countryCodeAddress
   * @param {*} shippingMethodReference
   * @param {*} countryCodeOrder
   * @param {*} availableShippingMethods
   * @returns boolean
   */
  function countryIsInShippingZone(
    countryCodeAddress,
    shippingMethodReference = 'SHIPPING',
    countryCodeOrder = null,
    availableShippingMethods = []
  ) {
    const method = getShippingMethod(
      shippingMethodReference,
      countryCodeOrder,
      availableShippingMethods
    )

    // if no methods are found trigger a sentry error - return true -> CL logic will handle the error - in worst case order creation will fail
    if (!method?.shipping_zone) {
      Sentry.setContext('deviceSettings', {
        availableShippingMethods: deviceStore.settings.availableShippingMethods,
        countryCodeAddress,
        shippingMethodReference
      })
      Sentry.captureMessage('No Shipping Zone found', (scope) => {
        scope.setTransactionName('useShipment.countryIsInShippingZone')
        return scope
      })

      return true
    }

    if (method.shipping_zone) {
      const shippingZone = method?.shipping_zone
      const countries = shippingZone?.country_code_regex
      // check if country code is in shipping zone
      return countries.includes(countryCodeAddress)
    } else {
      return false
    }
  }

  /**
   * Get the available countries in the shipping zone.
   * You can also specify the shipping method reference, the country code of the order and the available shipping methods. If not specified, the default values are used.
   *
   * @param {*} shippingMethodReference
   * @param {*} countryCodeOrder
   * @param {*} availableShippingMethods
   * @returns array
   */
  function getAvailableZoneCountries(
    shippingMethodReference = 'SHIPPING',
    countryCodeOrder = null,
    availableShippingMethods = []
  ) {
    const method = getShippingMethod(
      shippingMethodReference,
      countryCodeOrder,
      availableShippingMethods
    )

    if (!method?.shipping_zone) {
      return []
    }

    const shippingZone = method?.shipping_zone
    const countries = shippingZone?.country_code_regex
    return countries.split('|')
  }

  return {
    setShippingMethod,
    setClShippingMethod,
    //setShippingAddress,
    setBillingAddress,
    setShippingAddressAndMethod,
    getAvailableShippingMethods,
    countryIsInShippingZone,
    getAvailableZoneCountries,
    fetchAvailableAllShippingMethods
  }
}
