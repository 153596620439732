import { inject } from 'vue'
import { useNotification } from '@/composables/useNotification.js'
import { getErrorMessage } from '@/utils/error.js'
import { marketHasAssemblyOverServiceHubEnabled } from '@/utils/order.js'
import { useShipment } from '@/composables/useShipment.js'
import { MODE } from '@/constants/device.js'
import { useDeviceStore } from '@/stores/device'

export function useMarket() {
  const cl = inject('cl')
  const Sentry = inject('Sentry')
  const supabase = inject('supabase')
      const { fetchAvailableAllShippingMethods } = useShipment()
    const deviceStore = useDeviceStore()

  async function getCityVouchers(marketId) {
    const market = await cl.markets.retrieve(marketId, {
      fields: {
        markets: ['metadata'],
      }
    })

    return market.metadata?.city_vouchers?.[0] ? market.metadata.city_vouchers : false
  }

  /**
   * Get markets list with `price_list` and `merchant.address` included and
   * filtered by given `filterName` and `term`.
   * Also removes entry with code BRN1.
   *
   * @param {string} filterName - name of filter to apply (e.g. name_or_code_cont_any)
   * @param {string} term - term to filter by
   * @return {Promise<Array>} - list of markets
   */
  async function getMarketsList(filterName, term) {
    const notification = useNotification()

    try {
      let markets = await cl.markets.list({
        include: ['price_list', 'merchant.address'],
        filters: {
          [filterName]: `${term}`,
          metadata_jcont: '{"status":"OPEN"}'
        }
      })
      // remove entry where code === BRN1
      markets = markets.filter((market) => market.code !== 'BRN1')
      return markets
    } catch (error) {
      notification.showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  /**
   * Gets the market settings from the given warehouse name.
   *
   * @param {string} wh - warehouse name
   * @return {Promise<Object>} - market settings
   *
   * The returned object has the following properties:
   * {
   *   stockLocationId: string,
   *   storeAddressId: string,
   *   warehouse: string,
   *   country: string,
   *   currency: string,
   *   language: string,
   *   marketId: string,
   *   name: string,
   *   serviceHubId: string,
   *   assemblyOnlyInServiceHub: boolean,
   *   tags: Array<string>,
   *   mode: string (STORE or SERVICE_HUB),
   *   availableShippingMethods: Array<string>,
   *   cityVouchers: boolean
   * }
   */
  async function getMarketSettingsFromWarehouse(wh) {
    try {
      const searchRes = await getMarketsList('name_eq', wh)
      if (searchRes.length) {
        const warehouse = searchRes[0]
        const tagsList = await cl.tags.list()
        const stockLocations = await cl.stock_locations.list({
          include: ['address'],
          filters: {
            code_eq: warehouse.code
          }
        })
        const shippingMethods = await fetchAvailableAllShippingMethods()
        const countryCode = warehouse?.merchant?.address?.country_code ?? 'NaN'
        const serviceHubId = await cl.stock_locations.list({
          filters: {
            code_eq: `SH${countryCode.toUpperCase()}`
          }
        })
  
        let mode = MODE.STORE
        if (['SHAT', 'SHCH', 'SHDE'].includes(warehouse.code)) {
          mode = MODE.SERVICE_HUB
        }
  
        const settings = {}
        settings.stockLocationId = stockLocations[0].id
        settings.storeAddressId = stockLocations[0].address.id
        settings.warehouse = warehouse.code
        settings.country = warehouse?.merchant?.address?.country_code.toLowerCase()
        settings.currency = warehouse?.price_list?.currency_code
        settings.language = warehouse?.metadata.language.toLowerCase()
        settings.marketId = warehouse?.id
        settings.name = warehouse?.name
        settings.serviceHubId = serviceHubId[0]?.id
        settings.assemblyOnlyInServiceHub = marketHasAssemblyOverServiceHubEnabled(warehouse)
        settings.tags = tagsList
        settings.mode = mode
        settings.availableShippingMethods = shippingMethods
        settings.cityVouchers = warehouse?.metadata?.city_vouchers
          ? warehouse.metadata.city_vouchers
          : false
  
        // check if the current employee is a store employee
        // On the very first login the user is not set in the store_employees table
        if (deviceStore.settings?.currentEmployee?.id) {
          await supabase
            .from('store_employees')
            .upsert({
              store_warehouse: warehouse.code,
              employee_id: deviceStore.settings?.currentEmployee?.id,
              app_environment: import.meta.env.VITE_WEB_ENV
            })
            .select()
        }
        return settings
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  // use this function to check if the market has assembly over service hub enabled not from settings
  async function getMarketAssemblyOnlyInServiceHub(marketId) {
    try {
      const market = await cl.markets.retrieve(marketId, {
        fields: {
          markets: ['metadata'],
        }
      })
      return marketHasAssemblyOverServiceHubEnabled(market)
    } catch (error) {
      Sentry.captureException(error)
      return false
    }
  }

  return {
    getCityVouchers,
    getMarketsList,
    getMarketSettingsFromWarehouse,
    getMarketAssemblyOnlyInServiceHub
  }
}
