import { reactive, toValue, computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const INFLUENCER = 'influencer'
const INFLUENCERS = [
  'influencer',
]

const EMPLOYEE = 'employee'
const EMPLOYEES = [
  'employee-ch-low',
  'employee-ch-mid',
  'employee-ch-full',
  'employee-de-low',
  'employee-de-mid',
  'employee-de-full',
  'employee-at-low',
  'employee-at-mid',
  'employee-at-full',
]

const INVESTOR = 'investor'
const INVESTORS = [
  'investors',
  'investors-eu',
  'investors-ch',
]

export async function useCustomerFlags({ storedList, displayedList }) {

  const { t } = useI18n()
  const cl = inject('cl')
  const clUtils = inject('clUtils')

  const ready = ref(false)
  const tags = ref([])

  onMounted(async () => {
    if (toValue(displayedList)?.length > 0) {
      tags.value = await clUtils.retrieveAll('tags')
      ready.value = true
    } else {
      ready.value = true
    }
  })

  const options = computed(() => {
    return [
      { value: EMPLOYEE, disabled: true },
      { value: INFLUENCER, disabled: false },
      { value: INVESTOR, disabled: true }
    ].map(({ value, disabled }) => {
      return { text: t(`customer_flags.${value}.label`), value, disabled }
    })
  })

  const storedToDisplayed = computed(() => {
    const value = toValue(storedList)
  
    if (!(value?.length > 0)) {
      return []
    }

    const items = []

    if (value.some(flag => INFLUENCERS.includes(flag))) {
      items.push(INFLUENCER)
    }

    if (value.some(flag => EMPLOYEES.includes(flag))) {
      items.push(EMPLOYEE)
    }

    if (value.some(flag => INVESTORS.includes(flag))) {
      items.push(INVESTOR)
    }

    return items
  })

  const displayedToStored = computed(() => {
    const displayed = toValue(displayedList)
    const stored = toValue(storedList)

    if (!(displayed?.length > 0 && stored?.length > 0)) {
      return undefined
    }

    const items = []

    if (displayed.includes(EMPLOYEE)) {
      const item = stored.find(flag => EMPLOYEES.includes(flag))
      if (item) {
        items.push(item)
      }
    }

    if (displayed.includes(INVESTOR)) {
      const item = stored.find(flag => INVESTORS.includes(flag))
      if (item) {
        items.push(item)
      }
    }

    if (displayed.includes(INFLUENCER)) {
      items.push(INFLUENCER)
    }

    const flags = items
      .map((flag) => {
        return tags.value.find((tag) => tag.name === flag)?.id
      })
      .filter(Boolean)
      .map((tagId) => {
        return cl.tags.relationship(tagId)
      })
    
    return flags.length > 0 ? flags : undefined
  })

  return reactive({
    ready,
    options,
    storedToDisplayed,
    displayedToStored
  })
}
