<template>
  <sl-dialog
    :open="uiStore.showDeviceMarketSettingsDialog"
    class="market-dialog"
    label="Market Information"
    @sl-request-close="preventClosingDialog"
  >
    <div v-if="uiStore.showDeviceMarketSettingsDialog" class="settings">
      <FormAutocomplete
        v-model="query"
        :options="marketsList"
        option-key="name"
        option-key2="code"
        @click="query !== '' ? setWarehouse() : null"
      />
      <sl-select
        v-model="marketSettings.deviceType"
        label="Select device type"
        data-testid="select-device-type-dropdown"
        size="medium"
        :disabled="searching"
        @sl-change="handleDeviceTypeChange"
      >
        <sl-option
          v-for="deviceType in userDeviceType"
          :key="deviceType"
          :data-testid="`select-device-type-dropdown-${deviceType.toLowerCase()}`"
          :value="deviceType"
        >
          {{ deviceType }}
        </sl-option>
      </sl-select>
      <sl-input
        v-if="marketSettings.deviceType === 'Store'"
        v-model="marketSettings.deviceId"
        :error="marketSettingsErrors.deviceId"
        label="Device ID"
      />
    </div>
    <sl-button
      slot="footer"
      class="market-dialog-footer-button"
      :disabled="!isFormValid || !marketSettings?.warehouse"
      :loading="searching"
      variant="primary"
      data-testid="market-information-submit-button"
      @click="handleCloseDialog"
    >
      {{ $t('globals.save') }}
    </sl-button>
  </sl-dialog>
</template>

<script setup>
import FormAutocomplete from '@/components/Form/FormAutocomplete.vue'
import { useEmployee } from '@/composables/useEmployee.js'
import { useMarket } from '@/composables/useMarket.js'
import { useNotification } from '@/composables/useNotification.js'
import { MODE } from '@/constants/device.js'
import { useDeviceStore } from '@/stores/device'
import { useUiStore } from '@/stores/ui.js'
import { watchDebounced } from '@vueuse/core'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/option/option'
import '@shoelace-style/shoelace/dist/components/select/select'

const Sentry = inject('Sentry')
const deviceStore = useDeviceStore()
const uiStore = useUiStore()
const notification = useNotification()
const userDeviceType = ['Store', 'Personal']
const { addEmployee } = useEmployee()
const router = useRouter()
const { getMarketsList, getMarketSettingsFromWarehouse } = useMarket()

const marketsList = ref([])
const query = ref('')
const searching = ref(false)

const isFormValid = computed(() => {
  if (!marketSettings.value.warehouse) {
    return false
  }

  if (!marketSettings.value?.deviceType) {
    return false
  }

  if (marketSettings.value?.deviceType === 'Store' && !marketSettings.value?.deviceId) {
    return false
  }

  return true
})

const marketSettings = ref({
  marketId: '',
  warehouse: '',
  deviceType: '',
  deviceId: '',
  country: '',
  currency: '',
  language: '',
  name: '',
  storeAddressId: '',
  serviceHubId: '',
  tags: [],
  assemblyOnlyInServiceHub: false,
  mode: MODE,
  availableShippingMethods: [],
  cityVouchers: false
})

const marketSettingsErrors = ref({
  warehouse: '',
  deviceType: '',
  deviceId: ''
})

onMounted(async () => {
  setDialogVisibility()
  deviceStore.setCurrentEmployee({})
})

function preventClosingDialog(event) {
  event.stopPropagation()
  if (['overlay', 'keyboard'].includes(event.detail.source)) {
    event.preventDefault()
  }
}

function handleDeviceTypeChange(event) {
  marketSettings.value.deviceType = event.target.value
  if (marketSettings.value.deviceType === 'Personal') {
    marketSettings.value.deviceId = deviceStore.user.email
  } else {
    marketSettings.value.deviceId = ''
  }
}

async function getMarketsOptions() {
  if (query.value === '') {
    marketsList.value = []
    return
  }
  searching.value = true

  try {
    marketsList.value = await getMarketsList('name_or_code_cont_any', query.value)
    searching.value = false

    if (marketsList.value?.length === 0) {
      marketSettingsErrors.value.warehouse = "Store doesn't exist"
      return
    }

    // reset markets list if the value is selected from the list of options
    // check if marketSettings.value.warehouse exists in marketList.value. compare by property name.
    if (!marketsList.value?.some((market) => market.name === query.value)) {
      return
    }

    marketsList.value = []
  } catch (e) {
    throw new Error(e)
  }
}

async function setWarehouse() {
  searching.value = true
  const settings = await getMarketSettingsFromWarehouse(query.value)
  if (settings?.marketId) {
    marketSettings.value = settings
  }
  searching.value = false
}

async function updateSettingsStore(settings) {
  if (settings.value) {
    deviceStore.$patch({
      settings: {
        stockLocationId: settings.value.stockLocationId,
        marketId: settings.value.marketId,
        warehouse: settings.value.warehouse,
        deviceType: settings.value.deviceType,
        deviceId:
          settings.value.deviceType === 'Store' ? settings.value.deviceId : deviceStore.user.email,
        country: settings.value.country.toLowerCase(),
        currency: settings.value.currency,
        language: settings.value.language.toLowerCase(),
        name: settings.value.name,
        storeAddressId: settings.value.storeAddressId,
        serviceHubId: settings.value.serviceHubId,
        assemblyOnlyInServiceHub: settings.value.assemblyOnlyInServiceHub,
        tags: settings.value.tags,
        availableShippingMethods: settings.value.availableShippingMethods,
        cityVouchers: settings.value.cityVouchers
      }
    })

    marketSettings.value.deviceId =
      settings.value.deviceType === 'Store' ? settings.value.deviceId : deviceStore.user.email
    // update Sentry tags
    Sentry.setTag('marketSettings', settings.value.warehouse)

    if (settings.value.deviceType === 'Store') {
      Sentry.setTag('deviceId', settings.value.deviceId)
    } else {
      await addEmployee(deviceStore.user.email)
    }
  }
}

function handleCloseDialog() {
  updateSettingsStore(marketSettings)
  query.value = ''
  localStorage.setItem('marketSettings', JSON.stringify(marketSettings.value))
  uiStore.showDeviceMarketSettingsDialog = false
  router.push({ name: 'home' })
  notification.showNotification({
    message: 'Your settings have been updated successfully.'
  })
}

watchDebounced(
  () => query.value,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      await getMarketsOptions()
    }
  },
  { debounce: 400, maxWait: 2000 }
)

function setDialogVisibility() {
  const marketSettings = localStorage.getItem('marketSettings')
  const parsedData = JSON.parse(marketSettings)

  if (parsedData && Object.keys(parsedData).length >= 3) {
    uiStore.showDeviceMarketSettingsDialog = false
  } else {
    uiStore.showDeviceMarketSettingsDialog = true
  }
}

watch(
  () => uiStore.showDeviceMarketSettingsDialog,
  (newVal) => {
    if (newVal) {
      setDialogVisibility()
    }
  },
  { immediate: true }
)
</script>

<style lang="postcss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  gap: var(--sl-spacing-medium);
  min-height: 220px;
}

.select-device-type {
  margin-bottom: var(--sl-spacing-small);
}

.market-dialog {
  &::part(close-button) {
    display: none;
  }

  &::part(body) {
    padding: 0 var(--sl-spacing-x-large);
    min-height: 180px;
  }
}

sl-dialog {
  &::part(footer) {
    align-self: flex-end;
  }
}

sl-select::part(form-control-label) {
  font-size: var(--sl-font-size-medium);
}
</style>
