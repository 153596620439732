export function sortOrdersItems(lineItems) {
  const typeOrder = {
    frame: 0,
    customMadeFrame: 1,
    sun: 2,
    lens: 3
  }

  function customSort(a, b) {
    const typeA = a?.item?.metadata?.type
    const typeB = b?.item?.metadata?.type

    const orderA = typeOrder[typeA] !== undefined ? typeOrder[typeA] : Infinity
    const orderB = typeOrder[typeB] !== undefined ? typeOrder[typeB] : Infinity

    return orderA - orderB
  }

  return lineItems?.sort(customSort)
}

export function isMainProduct(item) {
  return (
    item?.metadata?.type === 'frame' ||
    item?.metadata?.type === 'customMadeFrame' ||
    item?.metadata?.type === 'sun'
  )
}

export function getLegacyAttachments(orderAttachments, type) {
  const attachments = orderAttachments.filter((attachment) => attachment.reference_origin === type)

  // It's not possible to update the attachments in the import so we have to filter them by same reference and take the newest one
  // get all attachments with the same reference
  const attachmentsByReference = attachments.reduce((acc, attachment) => {
    if (!acc[attachment.reference]) {
      acc[attachment.reference] = []
    }
    acc[attachment.reference].push(attachment)
    return acc
  }, {})

  // get the newest attachment for each reference
  return Object.values(attachmentsByReference).map((attachments) =>
    attachments.reduce((acc, attachment) => {
      if (!acc || new Date(attachment.created_at) > new Date(acc.created_at)) {
        return attachment
      }
      return acc
    }, null)
  )
}

export function awaitingPayment(order) {
  return (
    order?.attachments?.some(
      (attachment) => attachment.reference_origin === 'payment--adyen-payment-link'
    ) &&
    order?.status === 'placed' &&
    !['paid', 'partially_paid', 'free'].includes(order.payment_status)
  )
}

export function calculateAmountCents(amount) {
  return Math.round((amount + Number.EPSILON) * 100)
}

export function assembledInServiceHubCheck(lineItem, market = null) {
  // always check if the market has the assembly over service hub enabled first
  if (
    market?.metadata &&
    marketHasAssemblyOverServiceHubEnabled(market) &&
    lineItemNeedsAssembly(lineItem)
  ) {
    return true
  }

  if (lineItem.metadata?.assembly_in_service_hub_only === true) {
    return true
  }

  const isAssembledInServiceHub = lineItem?.line_item_options?.some(
    (option) => option.sku_option.metadata.assembly_in_servicehub_only
  )

  return isAssembledInServiceHub
}

export function lineItemNeedsAssembly(lineItem) {
  return (
    ['customMadeFrame', 'frame'].includes(lineItem?.item?.metadata?.type) &&
    !lineItem?.metadata?.is_frame_only
  )
}

export function marketHasAssemblyOverServiceHubEnabled(market) {
  return market?.metadata?.assembly_only_in_servicehub ? true : false
}

// Special case for spare parts (Services) that are assembled in the service hub but send from the store to the service hub
// therefore the stock location should be always the store
export function serviceOverServiceHubCheck(item) {
  const key = 'assembly_in_servicehub_only'
  if (item?.metadata?.type === 'spare_part') {
    if (item?.item?.metadata && item?.item?.metadata[key]) {
      return true
    } else if (item?.sku?.metadata && item?.sku?.metadata[key]) {
      return true
    } else if (item?.metadata[key]) {
      return true
    }
  }

  return false
}

export function getServiceHubStockLocation(countryCode) {
  countryCode = countryCode ? countryCode.toUpperCase() : null
  if (['CH', 'LI'].includes(countryCode)) {
    return 'SHCH'
  } else if (['AT'].includes(countryCode)) {
    return 'SHAT'
  } else {
    return 'SHDE'
  }
}

export function showAssemblyOverServiceHub(lineItem) {
  return assembledInServiceHubCheck(lineItem) || serviceOverServiceHubCheck(lineItem)
}
