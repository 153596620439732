<template>
  <div class="form-autocomplete">
    <sl-input
      v-bind="$attrs"
      ref="autocompleteForm"
      v-model="selectedValue"
      label="Select store"
      size="medium"
    ></sl-input>
    <div v-show="areOptionsVisible" class="form-autocomplete__options">
      <sl-menu>
        <sl-menu-item
          v-for="(option, index) in options"
          :key="index"
          @click="handleSelectedOption(option[optionKey])"
        >
          <div class="option-item">
            <span> {{ option[optionKey] }}</span>
            <span v-if="optionKey2?.length">({{ option[optionKey2] }})</span>
          </div>
        </sl-menu-item>
      </sl-menu>
    </div>
  </div>
</template>
<script setup>
import '@shoelace-style/shoelace/dist/components/input/input'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'

import { computed, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  optionKey: {
    type: String,
    default: ''
  },
  optionKey2: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])
const areOptionsVisible = ref(false)
const autocompleteForm = ref()
const selectedOption = ref('')

const selectedValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

function handleSelectedOption(value) {
  areOptionsVisible.value = false
  selectedOption.value = value
  emit('update:modelValue', value)
}

onClickOutside(autocompleteForm, () => {
  areOptionsVisible.value = false
  emit('update:modelValue', selectedOption.value)
})

watch(
  () => props.options,
  (newOptions) => {
    if (newOptions.length > 0) {
      areOptionsVisible.value = true
    }
  },
  { deep: true, immediate: false }
)
</script>

<style scoped lang="postcss">
.form-autocomplete {
  &__options {
    position: relative;
    top: 12px;

    sl-menu {
      position: absolute;
      z-index: 1;
      width: 100%;
      max-height: 140px;
      top: -10px;
    }

    sl-menu:empty {
      display: none;
    }
  }

  .option-item {
    display: flex;
    gap: var(--sl-spacing-x-small);
  }
}
</style>
