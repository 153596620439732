import { defineStore } from 'pinia'

export const useAfterSalesStore = defineStore('afterSales', {
  state: () => ({
    action: null,
    currentStep: '',
    returnType: '',
    returnAddress: null,
    filter: 'warranty',
    orderId: null,
    order: null,
    originalOrder: null,
    fulfillments: [],
    selectedItems: [],
    detailsComplete: false,
    replacementType: 'frame',
    selectedSpareParts: [],
    paidAmount: 0,
    openAmount: 0,
    creditAmount: 0,
    insuranceGiftcardAmount: 0,
    paydWithGiftCard: false,
    previousDiscountAmountFloat: false,
    deductibleAmount: 0,
    retrospectiveAmount: 0,
    refundMethod: null,
    returnId: null,
    returnNumber: null,
    isLegacyOrder: false,
    maxReturnItems: 0,
    sendEmails: true,
    insuranceClaimDataPrefilled: false,
    shippableItems: ['frame', 'sun', 'accessory', 'customMadeFrame'],
    selectableStockLocationItems: ['frame', 'sun', 'accessory'],
    sparePartsItems: ['frame', 'sun', 'customMadeFrame'],
    insuranceClaimPersonData: {
      first_name: '',
      last_name: '',
      birth_date: '',
      line_1: '',
      addition: '',
      zip_code: '',
      city: '',
      country_code: '',
      email: '',
      phone: ''
    },
    insuranceData: {
      title: {},
      start_date: '',
      policy_id: '',
      status: '',
      fulfillment: '',
      insured_frame: '',
      glasses_type: '',
      deductiblePercentage: 0, // 20 oder 30
      deductibleAmount: 0, // bezahlter selbstbehalt
      currency_code: ''
    },
    insuranceCaseData: {
      theft: {
        reportedBy: '',
        reportedDate: '',
        incidentDate: '',
        incidentLocation: '',
        productLocation: '',
        description: '',
        theftFiled: '',
        authorityFileNumberDate: '',
        insuranceClaimed: ''
      },
      loss: {
        reportedBy: '',
        reportedDate: '',
        incidentDate: '',
        incidentLocation: '',
        productLocation: '',
        description: '',
        lossFiled: '',
        insuranceClaimed: ''
      },
      damage: {
        reportedBy: '',
        reportedDate: '',
        incidentDate: '',
        incidentLocation: '',
        unforeseenEvent: '',
        description: '',
        damage: '',
        productInOwnersPosession: ''
      }
    },
    insuranceApprovalData: {
      terms: false,
      first_name: '',
      last_name: '',
      store: '',
      date: ''
    },
    insuranceDecisionData: {
      accepted: '',
      date: '',
      rejectionReason: ''
    }
  })
})
