<template>
  <div
    v-if="props.note"
    class="appointment-note"
  >
    <div class="appointment-note__date">
      {{ props.note.created_at }}
    </div>
    <div class="appointment-note__description">
      {{ props.note.description }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  note: {
    type: Object,
    default() {
      return {}
    }
  }
})
</script>

<style scoped src="./AppointmentNote.css"></style>
