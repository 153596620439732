import { useStorage } from '@vueuse/core'
import { format, addDays } from 'date-fns'

import { computed } from 'vue'
import { useDeviceStore } from '@/stores/device'
import { useNotification } from '@/composables/useNotification'
import { useI18n } from 'vue-i18n'

export function useTimify() {
  const appid = import.meta.env.VITE_TIMIFY_APP_ID
  const appsecret = import.meta.env.VITE_TIMIFY_APP_SECRET
  const apiSecret = import.meta.env.VITE_TIMIFY_API_SECRET

  const { t } = useI18n()
  const notification = useNotification()
  const token = useStorage('viu-timify-token', null)
  const deviceStore = useDeviceStore()
  const deviceEmployee = computed(() => {
    const { eid, email, name } = deviceStore.settings.currentEmployee || {}
    return { eid, email, name }
  })

  async function fetchToken() {
    try {
      const response = await fetch('https://api.timify.com/v1/auth/token', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          appid,
          appsecret
        })
      })
      const data = await response.json()
      token.value = data?.accessToken
    } catch (error) {
      notification.showNotification({
        message: t('appointments.errors.network'),
        variant: 'danger'
      })
      throw error
    }
  }

  async function timifyFetch(url, options = {}, retry = true) {
    try {
      const { headers } = options
      const response = await fetch(url, {
        ...options,
        headers: {
          ...headers,
          'X-TIMIFY-API-Secret': apiSecret,
          Authorization: token.value
        }
      })
      const body = await response.json()
      if (['invalid token', 'expired token'].includes(body.error?.toLowerCase())) {
        await fetchToken()
        if (token.value && retry) {
          return timifyFetch(url, options, false)
        }
        throw new Error('Missing Timify token')
      }
      if (!response.ok) {
        throw new Error(body.name)
      }
      return body
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  return {
    async companies() {
      const response = await timifyFetch(`https://api.timify.com/v1/companies`, {
        headers: {
          Accept: 'application/json'
        }
      })
      return response?.data || []
    },
    appointments(companyId) {
      const from = new Date()
      const to = addDays(from, 27)
      from.setDate(from.getDate() - 14)
      return timifyFetch(
        `https://api.timify.com/v1/appointments?from_date=${format(from, 'yyyy-MM-dd')}&to_date=${format(to, 'yyyy-MM-dd')}`,
        {
          headers: {
            'company-id': companyId
          }
        }
      )
    },
    appointment({ companyId, appointmentId }) {
      return timifyFetch(`https://api.timify.com/v1/appointments/${appointmentId}`, {
        headers: {
          'company-id': companyId
        }
      })
    },
    services(companyId) {
      return timifyFetch(`https://api.timify.com/v1/services`, {
        headers: {
          'company-id': companyId
        }
      })
    },
    customerFields(companyId) {
      return timifyFetch(
        `https://api.timify.com/v1/customers/customfields?is_mandatory_online=true`,
        {
          headers: {
            'company-id': companyId
          }
        }
      )
    },
    resources(companyId) {
      return timifyFetch(`https://api.timify.com/v1/resources`, {
        headers: {
          'company-id': companyId
        }
      })
    },
    availability({ companyId, serviceId, resourceId, day, allowanceType, numberOfDays = 6 }) {
      const additionalDays = Array.from({ length: numberOfDays }, (_, i) => addDays(day, i + 1))
      const days = [day, ...additionalDays]
        .map((date) => `days[]=${format(date, 'yyyy-MM-dd')}`)
        .join('&')
      const resources = resourceId.map((id) => `resource_ids[]=${id}`).join('&')
      return timifyFetch(
        `https://api.timify.com/v1/availability?timezone=Europe/Berlin&service_id=${serviceId}&${resources}&${days}&allowance_type=${allowanceType}`,
        {
          headers: {
            'company-id': companyId
          }
        }
      )
    },
    user({ companyId, email }) {
      return timifyFetch(`https://api.timify.com/v1/customers?q=${email}`, {
        headers: {
          'company-id': companyId
        }
      })
    },
    userCreate({ companyId, fields }) {
      return timifyFetch('https://api.timify.com/v1/customers', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'company-id': companyId
        },
        body: JSON.stringify({
          customfields: fields
        })
      })
    },
    timeshifts({ companyId, resourceIds, date }) {
      return timifyFetch(
        `https://api.timify.com/v1/timeshifts?resource_ids[]=${resourceIds[0]}&day[]=${format(date, 'yyyy-MM-dd')}`,
        {
          headers: {
            'company-id': companyId
          }
        }
      )
    },
    book({ datetime, serviceId, companyId, customerId, resourceIds, metadata = {} }) {
      metadata = {
        created_by_employee: deviceEmployee.value,
        ...metadata
      }
      return timifyFetch(`https://api.timify.com/v1/appointments`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'company-id': companyId
        },
        body: JSON.stringify({
          datetime,
          service_id: serviceId,
          customer_id: customerId,
          resource_ids: resourceIds,
          has_notify_customers: false,
          metadata
        })
      })
    },
    change({ appointmentId, datetime, serviceId, companyId, customerId, resourceIds, tagIds }) {
      return timifyFetch(`https://api.timify.com/v1/appointments/${appointmentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'company-id': companyId
        },
        body: JSON.stringify({
          datetime,
          service_id: serviceId,
          customer_id: customerId,
          resource_ids: resourceIds,
          tag_ids: tagIds
        })
      })
    },
    cancel({ companyId, appointmentId }) {
      return timifyFetch(`https://api.timify.com/v1/appointments/${appointmentId}`, {
        method: 'delete',
        headers: {
          'company-id': companyId
        }
      })
    },
    async bookerTimeslot({ companyId, serviceId, date, time }) {
      const response = await fetch('https://api.timify.com/v1/booker-services/reservations', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: companyId,
          service_id: serviceId,
          date,
          time
        })
      })
      return response.json()
    },
    async bookerConfirm({ companyId, reservationId, secret, fields, locale, metadata = {} }) {
      metadata = {
        created_by_employee: deviceEmployee.value,
        locale,
        ...metadata
      }
      const response = await fetch(
        'https://api.timify.com/v1/booker-services/appointments/confirm',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_id: companyId,
            reservation_id: reservationId,
            secret,
            fields,
            metadata
          })
        }
      )
      return response.json()
    }
  }
}
