import { ref, computed, watch } from 'vue'
import { format } from 'date-fns'
import { defineStore } from 'pinia'
import _slugify from 'slugify'
import { useI18n } from 'vue-i18n'
import { useNow } from '@/composables/useNow'
import { useTimify } from '@/composables/useTimify'
import { useDeviceStore } from '@/stores/device'
import { useNotification } from '@/composables/useNotification'

const appointmentColors = {
  default: { color: '#3788d8' },
  noShow: { color: '#4E4E4E' },
  'Abholung / Service': { color: '#49DE80' },
  Beratung: { color: '#2D98AF' },
  'Kostenloser Sehtest': { color: '#96C3FF' },
  Sehtest: { color: '#96C3FF' },
  Führerscheinsehtest: { color: '#8A94E9' },
  Reklamation: { color: '#FF9999' },
  'Store Admin 30 Min': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'Store Admin 60 Min': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'Eyetest Walk-In': { color: '#96C3FF' },
  'ACUITY EYE TEST (EU)': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'ACUITY EYETEST (CH)': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'ACUITY STILBERATUNG': { color: '#ccb0be' },
  'Mounting': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
}

const pastAppointmentColors = {
  default: { color: '#B8D6FF' },
  noShow: { color: '#4e4e4e' },
  'Abholung / Service': { color: '#C2F1D6' },
  Beratung: { color: '#B8DDE7' },
  'Kostenloser Sehtest': { color: '#DEE9F7' },
  Sehtest: { color: '#DEE9F7' },
  Führerscheinsehtest: { color: '#D8DCF7' },
  Reklamation: { color: '#FFE0E0' },
  'Store Admin 30 Min': {
    backgroundColor: '#F7F7F7',
    borderColor: '#F7F7F7',
    textColor: '#BEBEBE'
  },
  'Store Admin 60 Min': {
    backgroundColor: '#F7F7F7',
    borderColor: '#F7F7F7',
    textColor: '#BEBEBE'
  },
  'Eyetest Walk-In': { color: '#DEE9F7' },
  'ACUITY EYE TEST (EU)': {
    backgroundColor: '#F7F7F7',
    borderColor: '#F7F7F7',
    textColor: '#BEBEBE'
  },
  'ACUITY EYETEST (CH)': {
    backgroundColor: '#F7F7F7',
    borderColor: '#F7F7F7',
    textColor: '#BEBEBE'
  },
  'ACUITY STILBERATUNG': { color: '#EDE3E8' },
  'Mounting': {
    backgroundColor: '#F7F7F7',
    borderColor: '#F7F7F7',
    textColor: '#BEBEBE'
  },
}

export const useAppointmentsStore = defineStore('appointments', () => {

  const { t } = useI18n()
  const now = useNow()
  const deviceStore = useDeviceStore()
  const timify = useTimify()
  const notification = useNotification()

  const enabled = ref(false)
  const fetched = ref(false)
  const companies = ref([])
  const appointments = ref([])
  const modifyDialogOpen = ref(false)
  const entryDialogOpen = ref(false)
  const selectedAppointmentId = ref(null)
  let companiesRequest = null

  const employeeUnwatch = watch(() => deviceStore.settings.currentEmployee, async (value) => {
    if (value?.id) {
      try {
        companiesRequest = timify.companies()
        companies.value = await companiesRequest
        employeeUnwatch()
      } catch {
        //
      }
    }
  }, { immediate: true })

  const selectedAppointment = computed(() => {
    return viuAppointments.value?.find((appointment) => appointment.id === selectedAppointmentId.value)
  })

  const selectedAppointmentDateTimeModel = computed(() => {
    if (!selectedAppointment.value) {
      return undefined
    }
    return format(selectedAppointment.value.start, "yyyy-MM-dd'T'HH:mm")
  })

  watch(selectedAppointmentId, (value) => {
    if (value) {
      modifyDialogOpen.value = true
    }
  })
  
  watch(modifyDialogOpen, (value) => {
    if (!value) {
      selectedAppointmentId.value = null
    }
  })

  const currentCompany = computed(() => {
    return companies.value?.find(company => {
      return company.externalId?.toLowerCase() === deviceStore.settings.warehouse?.toLowerCase()
    })
  })

  const currentCompanyId = computed(() => {
    return currentCompany.value?.id
  })

  let updateRequest

  async function updateAppointments() {
    try {
      const request = timify.appointments(currentCompanyId.value)
      updateRequest = request
      const response = await request
      if (request === updateRequest) {
        appointments.value = response?.data || []
        fetched.value = true
      }
    } catch {
      fetched.value = false
    }
  }

  const networkError = ref(false)
  const networkErrorDisplayDuration = 3000

  watch(networkError, (value) => {
    if (value) {
      notification.showNotification({
        message: t('appointments.errors.network'),
        variant: 'danger'
      })
    }
  })

  function displayNetworkError() {
    networkError.value = true

    setTimeout(() => {
      networkError.value = false
    }, networkErrorDisplayDuration)
  }

  async function getCompanyById(companyId) {
    await companiesRequest
    return companies.value.find(company => company.id === companyId)
  }

  watch(currentCompanyId, async (companyId) => {
    if (companyId) {
      enabled.value = true
      appointments.value = []
      await updateAppointments()
    } else {
      enabled.value = false
    }
  }, { immediate: true })

  const viuAppointments = computed(() => {
    return appointments.value
      .filter((appointment) => appointment.type === 'booking')
      .map((appointment) => {
        return {
          ...appointment,
          serviceKey: slugify(appointment.service?.name || appointment.title || '')
        }
      })
      .map((appointment) => {
        const { bookings } = appointment
        // we have multiple identical bookings, one for each resource I guess
        const [booking] = bookings
        const { interval } = booking
        const noShow = appointment.tags.some(tag => tag.externalId === 'no_show')
        const start = new Date(interval.begin)
        const colorPalette = start < new Date() ? pastAppointmentColors : appointmentColors
        // this uses faded colors for past appointments
        const colors = pickAppointmentColors(appointment, colorPalette, noShow)
        // this uses the same colors for past and future appointments
        const normalColors = pickAppointmentColors(appointment, appointmentColors, noShow)
        return {
          ...appointment,
          start,
          end: new Date(interval.end),
          viuTitle: appointmentTitle(appointment),
          noShow,
          colors,
          style: colorsAsStyle(colors),
          normalStyle: colorsAsStyle(normalColors)
        }
      })
  })

  const upcomingViuAppointments = computed(() => {
    return viuAppointments.value.filter((appointment) => {
      const start = new Date(appointment.start)
      start.setMinutes(start.getMinutes() + 10)
      return start >= now.value
    })
  })

  function appointmentTitle(appointment) {
    const [customer] = appointment.singleCustomers
    const employee = appointment.resources?.find((resource) => Boolean(resource.externalId))
    const employeeNamePieces = employee?.name.split(' ')
    const employeeFirstName = employeeNamePieces?.[0]
    const employeeLastName = employeeNamePieces?.[employeeNamePieces.length - 1]
    const employeeInitials = [
      Array.from(employeeFirstName || '')[0],
      Array.from(employeeLastName || '')[0]
    ]
      .filter(Boolean)
      .join('')
    const customerLastNameInitial = Array.from(customer?.lastName || '')[0]
    const customerName = [
      customer?.firstName,
      customerLastNameInitial ? `${customerLastNameInitial}.` : ''
    ]
      .filter(Boolean)
      .join(' ')
    let title
    if (!customerName) {
      title = [
        t(`appointments.services.${appointment.serviceKey}`, appointment.title),
        employeeInitials ? `(${employeeInitials})` : ''
      ]
        .filter(Boolean)
    } else {
      title = [customerName, employeeInitials ? `(${employeeInitials})` : '']
        .filter(Boolean)
    }
  
    return title
  }

  return {
    enabled,
    fetched,
    appointments,
    viuAppointments,
    upcomingViuAppointments,
    companies,
    currentCompany,
    currentCompanyId,
    selectedAppointmentId,
    selectedAppointment,
    selectedAppointmentDateTimeModel,
    modifyDialogOpen,
    entryDialogOpen,
    updateAppointments,
    displayNetworkError,
    getCompanyById
  }
})

function slugify(name, options = {}) {
  return _slugify(name, {
    lower: true,
    remove: /[./\\()]/,
    locale: 'de',
    ...options
  }).replace(/-/g, '_')
}

function pickAppointmentColors(appointment, colorPalette, noShow) {
  return noShow ? colorPalette['noShow'] : colorPalette[appointment.title] || colorPalette['default']
}

function colorsAsStyle(colors) {
  return {
    backgroundColor: colors.backgroundColor || colors.color,
    color: colors.textColor || '#ffffff'
  }
}
