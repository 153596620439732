import { useCart } from '@/composables/useCart.js'
import { useNotification } from '@/composables/useNotification.js'
import { BACK_ORDER_REFERENCE, STOCK_STATUS, WAREHOUSE_REFERENCE } from '@/constants/stock.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useDeviceStore } from '@/stores/device.js'
import { calculateDaysDifferenceFromToday } from '@/utils/dateTime.js'
import { getErrorMessage } from '@/utils/error.js'
import { computed, inject, ref } from 'vue'

export function useStock() {
  const cl = inject('cl')
  const { showNotification } = useNotification()
  const { cartUpdate } = useCart()
  const deviceStore = useDeviceStore()
  const customerStore = useCustomerStore()
  const countryCode = computed(() => deviceStore.getStoreCountryCode)
  const initialStoreReference = computed(() => deviceStore.settings.warehouse)
  const SERVICE_HUB_REFERENCE = `SH${countryCode.value.toUpperCase()}`

  const statusColor = ref('red')
  const configuratorOriginStockLocation = ref('')
  const stocks = ref([])

  const storeReference = computed(() => {
    if (customerStore.customer?.currentCart?.metadata?.pickup_location) {
      return customerStore.customer.currentCart.metadata.pickup_location
    }
    return initialStoreReference.value
  })

  async function getStockLocations(skuCode, stockLocationCode = null, orderServiceHub = null) {
    const storeCode = stockLocationCode ? stockLocationCode : storeReference.value
    const serviceHubCode = orderServiceHub ? orderServiceHub : SERVICE_HUB_REFERENCE
    try {
      stocks.value = await cl.stock_items.list({
        include: ['stock_location', 'reserved_stock'],
        filters: {
          sku_code_eq: skuCode,
          stock_location_code_in: `${storeCode},${serviceHubCode},${WAREHOUSE_REFERENCE},${BACK_ORDER_REFERENCE}`
        },
        fields: {
          stock_items: ['quantity', 'metadata', 'stock_location', 'reserved_stock'],
          stock_locations: ['code', 'metadata', 'name']
        }
      })

      // foreach stock item, update the quantity to be the available quantity. the reserved stock might not exist.
      stocks.value.forEach((stock) => {
        stock.quantity = stock.quantity - (stock.reserved_stock?.quantity || 0)
      })

      return stocks.value
    } catch (error) {
      showNotification({
        message: 'getStockLocations: ' + getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function getRealStockForItem(sku, location) {
    return stocks.value.filter((stock) => stock.stock_location.code === location)
  }

  async function setStockStatusColor(skuCode, originStockLocationCode) {
    try {
      const stockLocations = await getStockLocations(skuCode)
      setStockStatus(stockLocations, originStockLocationCode)
    } catch (error) {
      showNotification({
        message: 'setStockStatusColor: ' + getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  function setStockStatus(stocks, originStockLocationCode) {
    if (!stocks) {
      statusColor.value = STOCK_STATUS.UNAVAILABLE
      return
    }

    if (
      ![WAREHOUSE_REFERENCE, BACK_ORDER_REFERENCE, SERVICE_HUB_REFERENCE].includes(
        originStockLocationCode
      )
    ) {
      const market = stocks.find((stock) => stock.stock_location.code === originStockLocationCode)

      if (market?.quantity > 0) {
        statusColor.value = STOCK_STATUS.AVAILABLE
      }
      return
    }

    const warehouse = stocks.find((stock) => {
      if (
        stock.stock_location.code === WAREHOUSE_REFERENCE &&
        originStockLocationCode === WAREHOUSE_REFERENCE
      ) {
        return stock
      }
    })

    if (warehouse?.quantity > 0) {
      statusColor.value = STOCK_STATUS.AVAILABLE_IN_WAREHOUSE
      return
    }

    const serviceHub = stocks.find((stock) => {
      if (
        stock.stock_location.code === SERVICE_HUB_REFERENCE &&
        originStockLocationCode === SERVICE_HUB_REFERENCE
      ) {
        return stock
      }
    })

    if (serviceHub?.quantity > 0) {
      statusColor.value = STOCK_STATUS.AVAILABLE
      return
    }

    const backOrder = stocks.find((stock) => stock.stock_location.code === BACK_ORDER_REFERENCE)

    if (
      !isAvailableForOrder(
        backOrder?.metadata?.estimated_availability_date,
        backOrder?.metadata?.available_for_preorder
      )
    ) {
      statusColor.value = STOCK_STATUS.UNAVAILABLE
      return
    }

    if (backOrder?.quantity > 0) {
      statusColor.value = STOCK_STATUS.ORDERED_AT_SUPPLIER
      return
    }

    if (!serviceHub || serviceHub.quantity === 0) {
      statusColor.value = STOCK_STATUS.UNAVAILABLE
      return
    }

    statusColor.value = STOCK_STATUS.UNAVAILABLE
  }

  function setStockLocation(lineItem) {
    return (
      lineItem.item?.do_not_track === false &&
      lineItem?.metadata?.launch_date === undefined &&
      lineItem.item?.metadata?.type !== 'customMadeFrame' &&
      lineItem.item?.metadata?.type !== 'lens'
    )
  }

  // TODO: This function can be removed in the future,
  // as on the later stage every product would have origin stock location
  async function setDefaultIfMissingStockOriginLocationOnCartItem(
    metadata,
    lineItemId,
    skuCode,
    doNotTrack
  ) {
    try {
      if (metadata.origin_stock_location === undefined) {
        const attributes = {
          id: lineItemId,
          metadata: {
            ...metadata
          }
        }

        if (!doNotTrack && setStockLocation(attributes)) {
          attributes.metadata.origin_stock_location = WAREHOUSE_REFERENCE
        }

        const stockLocations = await getStockLocations(skuCode)
        const store = stockLocations.find(
          (stock) => stock.stock_location.code === storeReference.value
        )

        if (!store) {
          return
        }

        if (store?.reserved_stock?.quantity >= store.quantity && setStockLocation(attributes)) {
          attributes.metadata.origin_stock_location = storeReference.value
        }

        await cl.line_items.update(attributes)
        await cartUpdate()
        setStockStatus(stockLocations, attributes.metadata.origin_stock_location)
      }
    } catch (error) {
      showNotification({
        message: 'setDefaultIfMissingStockOriginLocationOnCartItem: ' + getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function getOriginStockLocationForConfigurator(skuCode, marketCode) {
    const stockLocations = await getStockLocations(
      skuCode,
      marketCode ? marketCode : storeReference.value
    )
    const store = findStockLocationByCode(marketCode ? marketCode : storeReference.value)
    const warehouse = findStockLocationByCode(WAREHOUSE_REFERENCE)
    const backOrder = findStockLocationByCode(BACK_ORDER_REFERENCE)
    //const serviceHub = findStockLocationByCode(SERVICE_HUB_REFERENCE)

    const isAvailableInStock = (item) => item?.quantity > 0
    const isAvailableForOrder = (item) => {
      const metadata = item?.metadata
      return metadata?.estimated_availability_date && metadata?.available_for_preorder
    }

    let stockLocation, originStockLocation

    if (
      (isAvailableInStock(store) && store?.quantity > 1) ||
      (!isAvailableInStock(warehouse) && store?.quantity === 1)
    ) {
      stockLocation = marketCode ? marketCode : storeReference.value
      originStockLocation = marketCode ? marketCode : storeReference.value
    } else if (isAvailableInStock(warehouse)) {
      stockLocation = WAREHOUSE_REFERENCE
      originStockLocation = WAREHOUSE_REFERENCE
    } else if (isAvailableInStock(backOrder) && isAvailableForOrder(backOrder)) {
      stockLocation = BACK_ORDER_REFERENCE
      originStockLocation = BACK_ORDER_REFERENCE
    } else {
      stockLocation = SERVICE_HUB_REFERENCE
      originStockLocation = SERVICE_HUB_REFERENCE
    }

    setStockStatus(stockLocations, stockLocation)
    configuratorOriginStockLocation.value = originStockLocation

    return configuratorOriginStockLocation.value
  }

  async function getStockLocationStoreExcluded(skuCode) {
    const stockLocations = await getStockLocations(skuCode)
    const warehouse = findStockLocationByCode(WAREHOUSE_REFERENCE)
    const backOrder = findStockLocationByCode(BACK_ORDER_REFERENCE)
    //const serviceHub = findStockLocationByCode(SERVICE_HUB_REFERENCE)

    const getAvailableQuantity = (item) => item?.quantity
    const isAvailableInStock = (item) => getAvailableQuantity(item) > 0
    const isAvailableForOrder = (item) => {
      const metadata = item?.metadata
      return metadata?.estimated_availability_date && metadata?.available_for_preorder
    }

    let stockLocation, originStockLocation

    if (
      isAvailableInStock(warehouse) ||
      (isAvailableInStock(backOrder) && isAvailableForOrder(backOrder))
    ) {
      stockLocation = WAREHOUSE_REFERENCE
      originStockLocation = WAREHOUSE_REFERENCE
    } else {
      stockLocation = SERVICE_HUB_REFERENCE
      originStockLocation = SERVICE_HUB_REFERENCE
    }

    setStockStatus(stockLocations, stockLocation)
    configuratorOriginStockLocation.value = originStockLocation

    return configuratorOriginStockLocation.value
  }

  // Backorder only may be selected as a warehouse if the availability date is within the next 3 weeks
  function isAvailableForOrder(estimatedAvailabilityDate, availableForPreorderDaysNumber) {
    if (estimatedAvailabilityDate && availableForPreorderDaysNumber) {
      if (calculateDaysDifferenceFromToday(estimatedAvailabilityDate) < 0) {
        return true
      }

      return (
        availableForPreorderDaysNumber <=
          calculateDaysDifferenceFromToday(estimatedAvailabilityDate) &&
        calculateDaysDifferenceFromToday(estimatedAvailabilityDate) >= 0
      )
    }

    return false
  }

  // Backorder only may be selected as a warehouse if the availability date is within the next 3 weeks
  function isBackOrderStockAvailableForOrder(originStockLocationCode) {
    const market = findStockLocationByCode(originStockLocationCode)
    const warehouse = findStockLocationByCode(WAREHOUSE_REFERENCE)
    const backOrder = findStockLocationByCode(BACK_ORDER_REFERENCE)
    const serviceHub = findStockLocationByCode(SERVICE_HUB_REFERENCE)

    const isAvailable = (item) => {
      return item?.quantity > 0
    }

    if (
      isAvailable(market) ||
      isAvailable(serviceHub) ||
      isAvailable(warehouse) ||
      isAvailable(backOrder)
    ) {
      return true
    }

    return isAvailableForOrder(
      backOrder?.metadata?.estimated_availability_date,
      backOrder?.metadata?.available_for_preorder
    )
  }

  function findStockLocationByCode(reference) {
    return stocks.value?.find((stock) => stock.stock_location?.code === reference)
  }

  async function stockCheckAvailability(sku, location) {
    const stock = await getRealStockForItem(sku, location)
    return stock[0]?.quantity > 0
  }

  async function getStockLocationForAssemblyInServiceHubItem(
    skuCode,
    currentOriginStockLocation = null
  ) {
    const SERVICE_HUB_REFERENCE = `SH${deviceStore.settings.country.toUpperCase()}`
    await getStockLocations(skuCode)

    // if current stock location already include service hub or warehouse, try to check if it's available on the current stock location
    // this code is need to avoid location change for the following case:
    // - user selects WH on configuration
    // - and then change the pickup location on the checkout page -> if we don't take the current stock location into account, the stock location will be changed to service hub
    if ([SERVICE_HUB_REFERENCE, WAREHOUSE_REFERENCE].includes(currentOriginStockLocation)) {
      const currentStockLocation = findStockLocationByCode(currentOriginStockLocation)
      if (currentStockLocation?.quantity > 0) {
        return currentOriginStockLocation
      }
    }

    const serviceHub = findStockLocationByCode(SERVICE_HUB_REFERENCE)

    if (serviceHub?.quantity > 0) {
      return SERVICE_HUB_REFERENCE
    } else {
      const warehouse = findStockLocationByCode(WAREHOUSE_REFERENCE)
      if (warehouse?.quantity > 0) {
        return WAREHOUSE_REFERENCE
      }
    }

    return SERVICE_HUB_REFERENCE
  }

  return {
    findStockLocationByCode,
    getStockLocations,
    setStockStatusColor,
    setStockLocation,
    setDefaultIfMissingStockOriginLocationOnCartItem,
    getOriginStockLocationForConfigurator,
    getStockLocationStoreExcluded,
    statusColor,
    isAvailableForOrder,
    isBackOrderStockAvailableForOrder,
    configuratorOriginStockLocation,
    getRealStockForItem,
    stockCheckAvailability,
    getStockLocationForAssemblyInServiceHubItem
  }
}
