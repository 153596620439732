<template>
  <Overlay
    type="dialog"
    :open="open"
    :closing-blocked="inProgress"
    :style="isNoShow ? `--sl-panel-background-color: #F5F5F5`: ''"
    @request-close="close"
  >

    <sl-badge
      v-if="isNoShow"
      slot="label"
      variant="neutral"
    >
      No show
    </sl-badge>
    <div
      slot="header-actions"
      class="modify__actions"
    >
      <span
        v-if="loadedCustomer"
        class="modify__action"
        @click="newNoteOpen = true"
      >
        <PhNote :size="20" />
      </span>
      <span
        v-if="canModifyAppointment"
        class="modify__action"
        @click="onChange"
      >
        <PhClockClockwise :size="20" />
      </span>
      <span
        v-if="canModifyAppointment"
        class="modify__action"
        @click="deleteConfirmOpen = true"
      >
        <PhTrash :size="20" />
      </span>
      <span
        v-if="!canModifyAppointment && !props.appointment.noShow"
        class="modify__action"
        @click="noShowConfirmOpen = true"
      >
        <PhGhost :size="20" />
      </span>
    </div>
    <div
      v-if="props.appointment?.id"
      class="modify__content"
    >
      <div class="modify__section">
        <PhCalendarCheck
          :size="20"
          class="modify__section__icon"
        />
        <div
          :class="{
            'no-show-text': isNoShow
          }"
        >
          <h3 class="modify__title">
            {{ title }}
          </h3>
          <div class="modify__section__rows">
            <div>
              {{ formattedDate }} • {{ formattedTime }}
            </div>
            <div>
              {{ props.currentCompany.externalId }}
              <span v-for="employee in employees" :key="employee.name">
                {{ employee.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="customerName"
        class="modify__section"
      >
        <PhUser
          :size="20"
          class="modify__section__icon"
        />
        <div
          class="modify__section__content"
        >
          <div
            :class="{
              'no-show-text': isNoShow
            }"
            class="modify__user__person"
          >
            {{ customerName }}
            <CustomerFlags
              :items="(loadedCustomer?.tags || [])"
            />
          </div>
          <div
            class="modify__user__contact modify__section__rows"
            :class="{
              'no-show-text': isNoShow
            }"
          >
            <div>{{ customerEmail }}</div>
            <div>{{ customerPhone }}</div>
          </div>
          <div
            v-if="customerName"
            class="modify__user__load"
          >
            <sl-button
              variant="default"
              size="small"
              :disabled="!loadedCustomer"
              @click="onCustomerClick"
            >
              {{ $t('appointments.modify.load_customer') }}
            </sl-button>
          </div>
          <div
            v-if="hasAlerts"
            class="modify__user__alerts"
          >
            <sl-alert v-if="hasAlertNoCustomer" variant="danger" open>
              {{ $t('appointments.modify.customer_not_found') }}
            </sl-alert>
          </div>
        </div>
      </div>
      <div
        v-if="customerName"
        class="modify__section"
      >
        <PhNote
          :size="20"
          class="modify__section__icon"
        />
        <sl-details
          :summary="$t('appointments.modify.notes.title')"
          class="modify__section__content modify__section__notes"
          :class="{
            'modify__section__notes--noshow': isNoShow
          }"
        >
          <div class="modify__notes">
            <template v-if="notes.length === 0">
              <span style="color: grey">
                {{ $t('appointments.modify.notes.empty') }}
              </span>
            </template>
            <template v-else>
              <TransitionGroup
                name="notes"
              >
                <AppointmentNote
                  v-for="note in notes"
                  :key="note.description"
                  :note="note"
                />
              </TransitionGroup>
            </template>
          </div>
        </sl-details>
      </div>
    </div>
  </Overlay>
  <Overlay
    type="dialog"
    :open="deleteConfirmOpen"
    @request-close="onAppointmentDeleteCancel"
  >
    <h4 class="modify__cancel__title">
      {{ $t('appointments.modify.cancel_confirm.title') }}
    </h4>
    <p v-if="props.appointment.customers?.length > 0">
      {{ $t('appointments.modify.cancel_confirm.description') }}
    </p>
    <div slot="footer" style="display: flex; width: 100%; justify-content: space-between">
      <sl-button variant="default" @click="onAppointmentDeleteCancel">
        {{ $t('globals.cancel') }}
      </sl-button>
      <sl-button
        variant="primary"
        :disabled="deleteInProgress"
        :loading="deleteInProgress"
        @click="onAppointmentDeleteConfirm"
      >
        {{ $t('appointments.modify.cancel_confirm.confirm') }}
      </sl-button>
    </div>
  </Overlay>
  <Overlay
    type="dialog"
    :open="noShowConfirmOpen"
    @request-close="onNoShowCancel"
  >
    <h4 class="modify__cancel__title">
      {{ $t('appointments.modify.no_show_confirm.title') }}
    </h4>
    <p>
      {{ $t('appointments.modify.no_show_confirm.description') }}
    </p>
    <div slot="footer" style="display: flex; width: 100%; justify-content: space-between">
      <sl-button variant="default" @click="onNoShowCancel">
        {{ $t('globals.cancel') }}
      </sl-button>
      <sl-button
        variant="primary"
        :disabled="noShowInProgress"
        :loading="noShowInProgress"
        @click="onNoShowConfirm"
      >
        {{ $t('appointments.modify.no_show_confirm.confirm') }}
      </sl-button>
    </div>
  </Overlay>
  <Overlay
    type="dialog"
    :open="newNoteOpen"
    :label="$t('appointments.modify.new_note.title')"
    style="--width: 600px"
    @request-close="onNewNoteCancel"
  >
    <div class="note">
      <p class="note__description">
        {{ $t('appointments.modify.new_note.description') }}
      </p>
      <div class="note__content">
        <div class="note__user">
          <PhUser
            :size="20"
            class="note__user__icon"
          />
          <span class="note__user__name">
            {{ customerName }}
            <CustomerFlags
              :items="(loadedCustomer?.tags || [])"
            />
          </span>
        </div>
        <sl-textarea
          v-model="newNote"
          :placeholder="$t('appointments.modify.new_note.text.placeholder')"
        ></sl-textarea>
      </div>
    </div>
    <div slot="footer" style="display: flex; width: 100%; justify-content: space-between">
      <sl-button variant="default" @click="onNewNoteCancel">
        {{ $t('globals.cancel') }}
      </sl-button>
      <sl-button
        variant="primary"
        :disabled="newNoteInProgress || !newNote"
        :loading="newNoteInProgress"
        @click="onNewNoteConfirm"
      >
        {{ $t('globals.save') }}
      </sl-button>
    </div>
  </Overlay>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/details/details'
import '@shoelace-style/shoelace/dist/components/textarea/textarea'

import { computed, ref, watch } from 'vue'
import { format } from 'date-fns'
import {
  PhGhost,
  PhNote,
  PhClockClockwise,
  PhTrash,
  PhCalendarCheck,
  PhUser
} from '@phosphor-icons/vue'

import Overlay from '@/components/shared/Overlay/Overlay.vue'
import CustomerFlags from '@/components/shared/CustomerFlags/CustomerFlags.vue'
import AppointmentNote from '@/components/shared/AppointmentNote/AppointmentNote.vue'

import { useAppointmentsStore } from '@/stores/appointments'
import { useTimify } from '@/composables/useTimify.js'
import { useI18n } from 'vue-i18n'
import { useNotification } from '@/composables/useNotification.js'
import { useCustomer } from '@/composables/useCustomer.js'
import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useUiStore } from '@/stores/ui.js'
import { useNotes } from '@/composables/useNotes.js'

const emit = defineEmits(['changed', 'edit'])

const props = defineProps({
  appointment: {
    type: Object,
    default() {
      return {}
    }
  },
  currentCompany: {
    type: Object,
    default() {
      return {}
    }
  }
})

const { locale, t } = useI18n()
const timify = useTimify()
const notification = useNotification()
const { loadCustomerByEmail } = useCustomer()
const customerStore = useCustomerStore()
const cartStore = useCartStore()
const uiStore = useUiStore()
const { fetchCustomerNotes, createCustomerNote } = useNotes()
const appointmentsStore = useAppointmentsStore()

const currentLocale = computed(() => {
  let currentLocale = locale.value
  if (currentLocale.includes('de')) {
    currentLocale = 'de-de'
  } else if (currentLocale.includes('en')) {
    currentLocale = 'en-gb'
  } else if (currentLocale.includes('fr')) {
    currentLocale = 'fr-fr'
  }

  return currentLocale
})

const open = defineModel('open', {
  type: Boolean,
  default: false
})

function close() {
  open.value = false
}

const customer = computed(() => {
  return props.appointment?.singleCustomers?.[0]
})
const customerEmail = computed(() => {
  return customer.value?.email
})
const customerPhone = computed(() => {
  return customer.value?.phone?.phone
})
const customerName = computed(() => {
  return customer.value?.fullName
})
const employees = computed(() => {
  return (props.appointment?.resources || []).filter((resource) => Boolean(resource.externalId))
})

const isNoShow = computed(() => {
  return props.appointment.noShow
})

const hasAlertNoCustomer = computed(() => {
  return loadedCustomerReady.value && !loadedCustomer.value
})

const hasAlerts = computed(() => {
  return hasAlertNoCustomer.value
})

const title = computed(() => {
  return [
    props.appointment?.serviceKey
      ? t(`appointments.services.${props.appointment?.serviceKey}`, props.appointment?.title)
      : props.appointment?.title,
    props.appointment?.duration
      ? t('appointments.duration_title', { duration: props.appointment.duration })
      : ''
  ]
    .filter(Boolean)
    .join(' ')
})

const formattedDate = computed(() => {
  if (!props.appointment.start) {
    return ''
  }
  return new Intl.DateTimeFormat(currentLocale.value, {
    dateStyle: 'full'
  }).format(props.appointment.start)
})

const formattedTime = computed(() => {
  if (!props.appointment.start) {
    return ''
  }
  return [format(props.appointment.start, 'HH:mm'), format(props.appointment.end, 'HH:mm')]
    .filter(Boolean)
    .join(' – ')
})

// delete
const canModifyAppointment = computed(() => {
  return new Date() < props.appointment.start
})
const deleteInProgress = ref(false)
const inProgress = computed(() => {
  return deleteInProgress.value
})

const deleteConfirmOpen = ref(false)

async function onAppointmentDeleteConfirm() {
  deleteInProgress.value = true
  try {
    await timify.cancel({
      appointmentId: props.appointment.id,
      companyId: props.currentCompany?.id
    })
    emit('changed')
    notification.showNotification({
      message: t('appointments.modify.cancel_confirm.success'),
      variant: 'success'
    })
  } catch {
    notification.showNotification({
      message: t('appointments.modify.cancel_confirm.failure'),
      variant: 'danger'
    })
  } finally {
    deleteInProgress.value = false
    deleteConfirmOpen.value = false
    open.value = false
  }
}

function onAppointmentDeleteCancel() {
  deleteConfirmOpen.value = false
}

function onChange() {
  emit('edit')
}

// todo move to appointments store
// is done again in app.vue when editing
const loadedCustomer = ref(null)
const loadedCustomerReady = ref(false)
const notesData = ref([])

const notes = computed(() => {
  return (notesData.value || [])
    .slice(0, 2)
    .map(note => {
      const { description = '', created_at } = note
      const maxLength = 100
      const truncatedDescription = description.length > maxLength - 3 ? `${description.slice(0, maxLength)}...` : description
      return {
        description: truncatedDescription,
        created_at: new Date(created_at).toLocaleDateString(currentLocale.value)
      }
    })
})

watch(customerEmail, async value => {
  notesData.value = []
  if (value) {
    loadedCustomer.value = await loadCustomerByEmail(value)
    loadedCustomerReady.value = true
    notesData.value = await fetchCustomerNotes(loadedCustomer.value.id)
  } else {
    loadedCustomer.value = null
    loadedCustomerReady.value = false
  }
}, { immediate: true })

async function onCustomerClick() {
  if (loadedCustomer.value) {
    customerStore.setCustomer(loadedCustomer.value)
    //
    const billingAddress = loadedCustomer.value.customer_addresses?.filter(
      (item) => item.address.metadata?.is_default_billing
    )
    const shippingAddress = loadedCustomer.value.customer_addresses?.filter(
      (item) => item.address.metadata?.is_default_shipping
    )
    cartStore.setBillingAddress(billingAddress?.[0]?.address || {})
    cartStore.setShippingAddress(shippingAddress?.[0]?.address || {})
    open.value = false
    uiStore.$patch({ currentCustomerDrawer: 'profile' })
  }
}

const noShowConfirmOpen = ref(false)
const noShowInProgress = ref(false)

function onNoShowCancel() {
  noShowConfirmOpen.value = false
}

async function onNoShowConfirm() {
  noShowInProgress.value = true
  try {
    await timify.change({
      appointmentId: props.appointment.id,
      companyId: props.currentCompany?.id,
      tagIds: ['676172ef90313cbc66dc74b6']
    })
    emit('changed')
    notification.showNotification({
      message: t('appointments.modify.no_show_confirm.success'),
      variant: 'success'
    })
  } catch {
    notification.showNotification({
      message: t('appointments.modify.no_show_confirm.failure'),
      variant: 'danger'
    })
  } finally {
    noShowInProgress.value = false
    noShowConfirmOpen.value = false
    open.value = false
  }
}

// new note
const newNote = ref('')
const newNoteOpen = ref(false)
const newNoteInProgress = ref(false)

function onNewNoteCancel() {
  newNoteOpen.value = false
}

const notesApiOptions = {
  errorNotify: false,
  errorCatch: false
}

async function onNewNoteConfirm() {
  if (!newNote.value || !loadedCustomer.value) {
    return
  }
  newNoteInProgress.value = true
  try {
    await createCustomerNote(loadedCustomer.value.id, newNote.value, {
      ...notesApiOptions,
      metadata: {
        timify_appointment: {
          id: props.appointment.id,
          company_id: appointmentsStore.currentCompany?.id
        }
      }
    })
    try {
      notesData.value = await fetchCustomerNotes(loadedCustomer.value.id)
    } catch {
      notesData.value.unshift({
        description: newNote.value,
        created_at: new Date().toISOString()
      })
    }
    newNoteOpen.value = false
    newNote.value = ''
  } catch {
    notification.showNotification({
      message: t('appointments.modify.new_note.error'),
      variant: 'danger'
    })
  } finally {
    newNoteInProgress.value = false
  }
}
</script>

<style scoped src="./AppointmentsModify.css"></style>
