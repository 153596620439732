import { computed, inject, ref } from 'vue'
import { useCustomerStore } from '@/stores/customer.js'
import { useCartStore } from '@/stores/cart.js'
import { useNotification } from '@/composables/useNotification.js'
import { useAddress } from '@/composables/useAddress'
import { SHIPPING_METHOD } from '@/constants/shipping.js'
import { useI18n } from 'vue-i18n'

class CartValidationError {
  constructor(code, title, details = {}, helpText = '') {
    this.code = code
    this.title = title
    this.details = details
    this.helpText = helpText
  }

  toString() {
    return `${this.code}: ${this.title} - ${JSON.stringify(this.details)}`
  }
}

/**
 * Class to group line items by stock location and check availability
 *
 * This class will group the line items by stock location and then check the availability for each item in the stock location
 * It will then add the availability to the line items and add an error if the requested quantity exceeds the available quantity
 *
 */
class StockLocationGroup {
  constructor(originStockLocation, client) {
    this.originStockLocation = originStockLocation
    this.skuQuantities = {}
    this.skuAvailability = {}
    this.cl = client
  }

  // Method to add a line item to the group
  addItem(item) {
    const { sku_code, quantity } = item

    // If the sku_code is already in the group, add to the total quantity
    if (!this.skuQuantities[sku_code]) {
      this.skuQuantities[sku_code] = 0
    }

    this.skuQuantities[sku_code] += quantity
  }

  // Method to get the total quantities per SKU code
  getSkuQuantities() {
    return this.skuQuantities
  }

  async getStock(skuCode, locationCode) {
    let stockItem = null
    try {
      const stockItems = await this.cl.stock_items.list({
        include: ['stock_location', 'reserved_stock'],
        filters: {
          sku_code_eq: skuCode,
          stock_location_code_eq: locationCode
        },
        fields: {
          stock_items: ['quantity', 'metadata', 'stock_location', 'reserved_stock'],
          stock_locations: ['code', 'metadata']
        }
      })

      if (stockItems.length === 1) {
        stockItem = stockItems[0]
      }
      return stockItem
    } catch (error) {
      return stockItem
    }
  }

  async checkAvailability() {
    const skuCodes = Object.keys(this.skuQuantities)

    // Example API call structure - this should be replaced with the actual API details
    for (const skuCode of skuCodes) {
      const quantityNeeded = this.skuQuantities[skuCode]

      try {
        let availableQuantity = 0
        let isAvailable = false

        const stockItem = await this.getStock(skuCode, this.originStockLocation)

        if (stockItem) {
          if (!stockItem.reserved_stock && stockItem.quantity >= quantityNeeded) {
            availableQuantity = stockItem.quantity
            isAvailable = true
          } else if (
            stockItem.reserved_stock &&
            stockItem.quantity - stockItem.reserved_stock.quantity >= quantityNeeded
          ) {
            availableQuantity = stockItem.quantity - stockItem.reserved_stock.quantity
            isAvailable = true
          } else if (!stockItem.reserved_stock && stockItem.quantity) {
            availableQuantity = stockItem.quantity
            isAvailable = false
          } else if (
            stockItem.reserved_stock &&
            stockItem.quantity - stockItem.reserved_stock.quantity
          ) {
            availableQuantity = stockItem.quantity - stockItem.reserved_stock.quantity
            isAvailable = false
          }
        }

        // Assuming API returns an object with 'available_quantity' and 'is_available' flags
        this.skuAvailability[skuCode] = {
          requested: quantityNeeded,
          available: availableQuantity,
          isAvailable: isAvailable
        }
      } catch (error) {
        this.skuAvailability[skuCode] = {
          requested: quantityNeeded,
          available: 0,
          isAvailable: false
        }
      }
    }
  }

  getSkuAvailability() {
    return this.skuAvailability
  }
}

export function useCartValidation() {
  const Sentry = inject('Sentry')
  const cl = inject('cl')
  const { t } = useI18n()

  const customerStore = useCustomerStore()
  const cartStore = useCartStore()
  const { showNotification } = useNotification()
  const { getAddressValidationSchema } = useAddress()

  const currentCart = computed(() => customerStore.getCurrentCart)
  const errors = ref([])
  const errorCodes = {
    MISSING_CUSTOM_MADE_OPTIONS: 'MISSING_CUSTOM_MADE_OPTIONS',
    SKU_STOCK_ERROR: 'SKU_STOCK_ERROR',
    DUPLICATE_LINE_ITEM_GROUP: 'DUPLICATE_LINE_ITEM_GROUP',
    MISSING_SHIPPING_ADDRESS: 'MISSING_SHIPPING_ADDRESS',
    MISSING_DELIVERY_METHOD: 'MISSING_DELIVERY_METHOD',
    MISSING_BILLING_ADDRESS: 'MISSING_BILLING_ADDRESS',
    MISSING_CUSTOMER_NAME: 'MISSING_CUSTOMER_NAME'
  }

  function addErrorToLineItem(lineItemId, error) {
    // get the index of the line item in the cart
    const index = currentCart.value.line_items.findIndex((cartItem) => cartItem.id === lineItemId)

    if (index === -1) {
      return
    }
    // check if the line item has a validationErrors array
    if (!currentCart.value.line_items[index].validationErrors) {
      currentCart.value.line_items[index].validationErrors = []
    }

    currentCart.value.line_items[index].validationErrors.push(error)
  }

  /**
   * Validate the custom made frame line items in the cart
   * Check if the custom made frame has the required options
   * Every custom made frame should have a ::Temple:: and ::Metal Component Part:: option
   * If the required options are missing, add an error to the line item
   */
  function validateCustomMadeFrame() {
    // find all line items of type customMadeFrame
    const customMadeFrames = currentCart.value.line_items.filter(
      (item) => item.metadata.type === 'customMadeFrame'
    )

    customMadeFrames.forEach((item) => {
      const options = item.line_item_options
      const temple = options.find((option) => option.name.includes('::Temple::'))
      const hasMetalComponent = options.some((option) =>
        option.name.includes('::Metal Component Part::')
      )

      // metal check need
      let metalCheck = false
      if (temple && temple?.name?.includes('F2108')) {
        metalCheck = true
      }

      let errorMessage
      if (!temple) {
        errorMessage = t('validation.customMadeFrame.missingTempleOption', {
          skuCode: item.sku_code
        })
      } else if (!hasMetalComponent && metalCheck) {
        errorMessage = t('validation.customMadeFrame.missingMetalComponentOption', {
          skuCode: item.sku_code
        })
      }

      if (errorMessage) {
        const error = new CartValidationError(
          errorCodes.MISSING_CUSTOM_MADE_OPTIONS,
          errorMessage,
          {
            id: item.id,
            skuCode: item.sku_code,
            reference: item.reference,
            missingOptions: {
              temple: !temple,
              metalComponent: !hasMetalComponent
            }
          },
          t('validation.customMadeFrame.helpText')
        )

        addErrorToLineItem(item.id, error)
        errors.value.push(error)
      }
    })
  }

  /**
   * Validate the line item group in the cart
   *
   * This function will check if there are any duplicate line item groups in the cart
   * If there are any duplicates, it will add an error to the cart
   */
  function validateLineItemGroup() {
    const mainItems = currentCart.value.line_items.filter((item) =>
      ['sun', 'frame', 'customMade'].includes(item.metadata.type)
    )
    const references = mainItems.map((item) => item.reference)

    const duplicateReferences = references.filter(
      (item, index) => references.indexOf(item) !== index
    )

    if (duplicateReferences.length > 0) {
      errors.value.push(
        new CartValidationError(
          errorCodes.DUPLICATE_LINE_ITEM_GROUP,
          t('validation.lineItems.duplicateLineItemGroup.title'),
          {
            references: duplicateReferences
          },
          t('validation.lineItems.duplicateLineItemGroup.helpText')
        )
      )
    }
  }

  /**
   * Validate the stock availability for the items in the cart
   *
   * This function will group the items by stock location and then check the availability for each item in the stock location
   * It will then add the availability to the line items
   * If the requested quantity exceeds the available quantity, it will add an error to the line item
   */
  async function validateStockAvailability() {
    const groupedStockLocations = {}

    currentCart.value.line_items.forEach((item) => {
      const { metadata } = item

      // if item metadata ignores stock, skip
      if (metadata.ignore_stock) {
        return
      }

      if (
        metadata.origin_stock_location &&
        !groupedStockLocations[metadata.origin_stock_location]
      ) {
        groupedStockLocations[metadata.origin_stock_location] = new StockLocationGroup(
          metadata.origin_stock_location,
          cl,
          errors.value
        )
      }

      if (metadata.origin_stock_location) {
        groupedStockLocations[metadata.origin_stock_location].addItem(item)
      }
    })

    for (const location in groupedStockLocations) {
      const group = groupedStockLocations[location]
      await group.checkAvailability()
    }

    // now add the availability to the line items
    currentCart.value.line_items.forEach((item) => {
      const { metadata } = item
      const trackable =
        (item.item?.do_not_ship === false || item.item?.do_not_track === false) &&
        !item.metadata?.ignore_stock

      if (metadata.origin_stock_location && trackable) {
        const group = groupedStockLocations[metadata.origin_stock_location]
        const skuAvailability = group.getSkuAvailability()
        const skuCode = item.sku_code
        item.availability = skuAvailability[skuCode]
        // add error if is not available
        if (!skuAvailability[skuCode].isAvailable) {
          const errorTitle = t('validation.lineItems.stockError.title', {
            skuCode: skuCode,
            requested: skuAvailability[skuCode].requested,
            available: skuAvailability[skuCode].available
          })
          const error = new CartValidationError(
            errorCodes.SKU_STOCK_ERROR,
            errorTitle,
            {
              stockLocation: metadata.origin_stock_location,
              skuCode,
              requested: skuAvailability[skuCode].requested,
              available: skuAvailability[skuCode].available
            },
            t('validation.lineItems.stockError.helpText', {
              skuCode: skuCode,
              stockLocation: metadata.origin_stock_location
            })
          )

          addErrorToLineItem(item.id, error)
          errors.value.push(error)
        }
      }
    })
  }

  function validateShipment(order) {
    // check if the order has a shipping address
    if (
      (!order.shipping_address?.id || !cartStore.shippingAddress?.id) &&
      cartStore.shippingMethod !== SHIPPING_METHOD.PICKUP
    ) {
      errors.value.push(
        new CartValidationError(
          errorCodes.MISSING_SHIPPING_ADDRESS,
          t('validation.shipping.missingShippingAddress.title'),
          {},
          t('validation.shipping.missingShippingAddress.helpText')
        )
      )
    }

    // check if the order has a billing address
    if (!order.billing_address?.id) {
      errors.value.push(
        new CartValidationError(
          errorCodes.MISSING_BILLING_ADDRESS,
          t('validation.shipping.missingBillingAddress.title'),
          {},
          t('validation.shipping.missingBillingAddress.helpText')
        )
      )
    }

    if (!order.metadata?.delivery_method) {
      errors.value.push(
        new CartValidationError(
          errorCodes.MISSING_DELIVERY_METHOD,
          t('validation.shipping.missingDeliveryMethod.title'),
          {},
          t('validation.shipping.missingDeliveryMethod.helpText')
        )
      )
    }
  }

  function validateCustomer(order) {
    // check if customer.metadata.first_name and last_name are set and contains at least 2 characters
    if (
      !order.customer?.metadata?.first_name ||
      order.customer?.metadata?.first_name.length < 2 ||
      !order.customer?.metadata?.last_name ||
      order.customer?.metadata?.last_name.length < 2
    ) {
      const error = new CartValidationError(
        errorCodes.MISSING_CUSTOMER_NAME,
        t('validation.customer.missingName.title'),
        {},
        t('validation.customer.missingName.helpText')
      )
      errors.value.push(error)
      customerStore.setCustomerValidationErrors('data', [error])
    }
  }

  function validateAddress(address, type, countryCode) {
    const validationSchema = getAddressValidationSchema(countryCode)

    try {
      validationSchema.validateSync(
        {
          firstName: address?.first_name || '',
          lastName: address?.last_name || '',
          street: address?.line_1 || '',
          postalCode: address?.zip_code || '',
          city: address?.city || ''
        },
        { abortEarly: false }
      )
      return true
    } catch (error) {
      const addressErrors = error.inner.map((err) => {
        return new CartValidationError(err.path, err.message, {}, err.message)
      })

      errors.value.push(...addressErrors)

      customerStore.setCustomerValidationErrors(type, addressErrors)
      return false
    }
  }

  function clearErrors() {
    errors.value = []
    customerStore.resetValidationErrors()

    if (currentCart.value) {
      // also on each line item
      currentCart.value.line_items?.forEach((item) => {
        delete item.availability
        delete item.validationErrors
      })
    }
  }

  async function cartValidate() {
    try {
      // reset errors
      clearErrors()

      // fetch current order
      const order = await cl.orders.retrieve(currentCart.value.id, {
        include: ['shipments.shipping_method', 'shipping_address', 'billing_address', 'customer'],
        fields: {
          orders: [
            'shipments',
            'shipping_address',
            'billing_address',
            'metadata',
            'customer',
            'guest'
          ],
          customers: ['metadata']
        }
      })

      await validateStockAvailability()
      validateLineItemGroup()
      validateCustomMadeFrame()
      if (!order.guest || customerStore.customer?.email) {
        validateShipment(order)
        validateCustomer(order)
        // add new validation for address only in test environment
        validateAddress(order.billing_address, 'billing_address', order.billing_address?.country_code)

        // if shipping method is not pickup, validate shipping address
        if (cartStore?.shippingMethod === SHIPPING_METHOD.SHIPPING) {
          validateAddress(order.shipping_address, 'shipping_address', order.shipping_address?.country_code)
        }
      }

      // remove duplicates from the errors by code and title
      errors.value = errors.value.filter(
        (error, index, self) =>
          index === self.findIndex((t) => t.code === error.code && t.title === error.title)
      )

      // add errors to the cart
      customerStore.setCartValidationErrors(errors.value)

      // Push notification for each error
      errors.value.forEach((error) => {
        showNotification({
          variant: 'danger',
          message: error.title
        })
      })

      return errors.value.length === 0
    } catch (error) {
      console.error('Error validating cart', error)
      Sentry.setContext('CartValidation', {
        cart: currentCart.value
      })
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('cartValidate')
      })
      return true
    }
  }

  return { cartValidate }
}
