import { inject } from 'vue'
import { useNotification } from '@/composables/useNotification.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useUiStore } from '@/stores/ui.js'

export function useCustomer() {
  const cl = inject('cl')

  const notification = useNotification()
  const uiStore = useUiStore()
  const customerStore = useCustomerStore()

  async function loadCustomerByEmail(email) {
    const customerList = await cl.customers.list({
      include: ['customer_addresses.address', 'tags'],
      filters: { email_eq: `${email}` }
    })

    return customerList.length > 0 ? customerList[0] : false
  }

  async function loadCustomerById(id) {
    const customer = await cl.customers.retrieve(id, {
      include: ['customer_addresses.address', 'tags']
    })

    return customer?.id ? customer : false
  }

  async function openCustomerOrderDetails(orderId, email) {
    let customer = false
    if (email) {
      customer = await loadCustomerByEmail(email)
    }

    if (!customer) {
      notification.showNotification({
        message: 'Customer could not be loaded.',
        variant: 'danger',
        duration: 6000,
        icon: 'person-x'
      })
      return
    }

    customerStore.setCustomer(customer)
    customerStore.customerOrderId = orderId
    uiStore.$patch({
      showCustomerOrderDetails: true
    })
  }

  function isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  return {
    loadCustomerByEmail,
    loadCustomerById,
    openCustomerOrderDetails,
    isValidEmail
  }
}
