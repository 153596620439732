<template>
  <div ref="customerCartDialog">
    <sl-dialog
      :open="isLoadingCustomer"
      class="customer-loading-dialog"
      style="--width: 240px"
      no-header
      @sl-request-close="preventClosingDialogOnOverlay"
    >
      <sl-spinner style="font-size: 3rem; --track-width: 4px"></sl-spinner>
      <div>{{ $t('globals.loading') }}...</div>
    </sl-dialog>

    <sl-dialog
      :open="isChooseCartVisible"
      :label="$t('cart.dialog.choose_cart.title')"
      style="--width: 744px"
      @sl-request-close="$emit('closeDialog')"
    >
      <ul v-if="customer.carts" class="carts">
        <li v-for="cart in formattedCarts" :key="cart.id" class="cart">
          <div class="cart__image-wrapper">
            <img
              v-if="cart.priorityItem?.imageUrl"
              class="cart__image"
              :src="cart.priorityItem.imageUrl"
            />
          </div>
          <div class="cart__frame-and-skus-count">
            <template v-if="cart.priorityItem?.title">
              {{ cart.priorityItem.title }}
              <template v-if="cart.skus_count >= 2">, +{{ cart.skus_count - 1 }}</template>
              <br />
            </template>
            <sl-badge v-if="cartHasAftersaleGiftcard(cart)">Aftersales</sl-badge>
          </div>
          <div class="cart__market-reference">
            <p v-if="cart.market?.code">{{ formatMarketCode(cart) }}</p>
          </div>
          <div class="cart__order-create-date">{{ formatISODateToDDMMYYYY(cart.created_at) }}</div>
          <div class="text-right">
            <sl-button
              class="cart__select-cart-button"
              size="medium"
              variant="primary"
              @click="setCart(cart)"
            >
              {{ $t('cart.dialog.choose_cart.button.open_cart') }}
            </sl-button>
          </div>
        </li>
      </ul>
      <div slot="footer" class="footer-actions">
        <sl-button variant="default" @click="$emit('closeDialog')">
          {{ $t('cart.dialog.choose_cart.button.continue_without') }}
        </sl-button>
      </div>
    </sl-dialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useCustomerStore } from '@/stores/customer'
import { useNotification } from '@/composables/useNotification.js'
import { useDeviceStore } from '@/stores/device.js'
import { formatISODateToDDMMYYYY } from '@/utils/dateTime.js'
import { useI18n } from 'vue-i18n'

const customerStore = useCustomerStore()
const deviceStore = useDeviceStore()
const notification = useNotification()
const customer = computed(() => customerStore.getCustomer)
const language = computed(() => deviceStore.settings.language)
const customerCartDialog = ref()
const { t } = useI18n()

const emit = defineEmits(['closeDialog'])

defineProps({
  isChooseCartVisible: {
    type: Boolean,
    default: false
  },
  isLoadingCustomer: {
    type: Boolean,
    default: false
  }
})

/**
 * Format cart data with priority item information
 */
const formattedCarts = computed(() => {
  if (!customer.value?.carts?.length) return []

  return customer.value.carts
    .filter(cart => cart.line_items.length > 0)
    .slice(0, 3)
    .map((cart) => {
      const priorityLineItem = findPriorityLineItem(cart)

      return {
        ...cart,
        priorityItem: priorityLineItem ? formatLineItem(priorityLineItem) : null
      }
    })
})

/**
 * Finds the line item with the highest priority in the given cart
 * Priority order: frame, sun, customMadeFrame, accessory, lens
 *
 * @param {Object} cart - The cart object with line_items
 * @returns {Object|null} The highest priority line item or null
 */
function findPriorityLineItem(cart) {
  // Check if cart has line_items
  if (
    !cart ||
    !cart.line_items ||
    !Array.isArray(cart.line_items) ||
    cart.line_items.length === 0
  ) {
    return null
  }

  const priorityTypes = ['frame', 'sun', 'customMadeFrame', 'accessory', 'lens']

  // Try to find items by priority type
  for (const type of priorityTypes) {
    const item = cart.line_items.find((lineItem) => {
      return lineItem?.item?.metadata?.type === type
    })

    if (item) {
      return item
    }
  }

  // If no priority type found, return the first line item
  return cart.line_items[0]
}

/**
 * Format a line item for display, extracting image and title
 *
 * @param {Object} lineItem - The line item object
 * @returns {Object} Formatted line item with image URL and title
 */
function formatLineItem(lineItem) {
  // Return empty data if line item is missing or is an accessory
  if (!lineItem || !lineItem.item) {
    return { imageUrl: null, title: null }
  }

  // Get image URL
  const baseUrl = lineItem.item.image_url
    ? lineItem.item.image_url.replace('?h=500', '?h=80')
    : null

  const imageUrl = baseUrl ? `${baseUrl}?w=80&fit=fill&crop=center&dpr=2&bg=F6F6F6&q=70` : null

  // Get title from metadata
  let title = null
  if (lineItem.item.metadata?.title && language.value) {
    title = lineItem.item.metadata.title[language.value]
  }

  return {
    imageUrl,
    title
  }
}

/**
 * Sets the customer's current cart in the customer store
 * @param {Object} cart - The cart to set
 */
function setCart(cart) {
  customerStore.setCurrentCart(cart)
  notification.showNotification({
    message: t('cart.dialog.cartSuccessfullyLoaded'),
    icon: 'cart-check'
  })
  emit('closeDialog')
}

/**
 * Checks if the given cart has an aftersales giftcard
 * @param {Object} cart - The cart to check
 * @returns {Boolean} True if cart has aftersale giftcard
 */
function cartHasAftersaleGiftcard(cart) {
  return cart.attachments?.some((attachment) =>
    attachment.metadata?.gift_card_reference?.startsWith('AS/')
  )
}

/**
 * Prevents closing a dialog on overlay or keyboard events
 * @param {Event} event - The event object
 */
function preventClosingDialogOnOverlay(event) {
  event.stopPropagation()
  if (['overlay', 'keyboard'].includes(event.detail.source)) {
    event.preventDefault()
  }
}

/**
 * Formats the market code for display
 * @param {Object} cart - The cart object
 * @returns {String} Formatted market code
 */
function formatMarketCode(cart) {
  const webCodes = ['SHDE', 'SHCH', 'SHAT']
  return webCodes.includes(cart.market?.code) && cart.metadata?.source === 'web'
    ? 'Web'
    : cart.market?.code
}
</script>

<style src="./CustomerCartDialog.css" scoped />
