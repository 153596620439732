import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', {
  state: () => ({
    showCustomerCreationEditDialog: false,
    showCustomerAddressCreationEditDialog: false,
    showAddPrescriptionDialog: false,
    showAnonymiseCustomerDialog: false,
    showCustomerDeleteAddressDialog: false,
    showDeviceMarketSettingsDialog: false,
    showCustomerOrderDetails: false,
    showCartOrderNoteDialog: false,
    showCartDiscountRemoveConfirmationDialog: false,
    showCartGiftcardRemoveConfirmationDialog: false,
    giftCardToRemove: null,
    showProductAvailabilityDialog: false,
    showCart: false,
    showRemoveCartDialog: false,
    showCartProductsDrawer: false,
    showCartChooseAddressDrawer: false,
    showCartPaymentDialog: false,
    showCartPaymentAlternatives: false,
    showCartMarketsDrawer: false,
    showCartCityVoucherDialog: false,
    showFulfillmentDetails: false,
    showCartAddressAddOrEditDialog: false,
    showCustomerSearchDialog: false,
    showStockTransferDetails: false,
    showIncomingGoodsParcelDrawer: false,
    currentCustomerDrawer: null,
    currentOrderCountryCode: null,
    prescriptionToEdit: null,
    prescriptionSaveButtonClicked: false,
    presciptionModelToApply: null,
    productWasAdded: false,
    applyPrescriptionOnSave: false,
    centeringDataValid: false,
    showCustomerBannedDialog: false,
    showEmailBannedOrInvalid: false,
    reloadPaymentsTab: false,
    reloadGoodwillTable: false,
    reloadOrderDetail: false,
    isOrderCopyInProgress: false,
    isHomescreenApp: false,
    showAppHeader: false,
    settingsLoaded: false
  })
})
