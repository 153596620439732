export const PAYMENT_METHOD = {
  CREDIT_CARD: 'CREDIT_CARD',
  CASH: 'CASH',
  GIFT_CARD: 'GIFT_CARD',
  RETROSPECTIVE_DISCOUNT: 'RETROSPECTIVE_DISCOUNT',
  MANUAL_REFUND: 'MANUAL_REFUND',
  PAYMENT_LINK: 'PAYMENT_LINK',
  PAY_AT_PICKUP: 'PAY_AT_PICKUP'
}

export const PAYMENT_TYPE = {
  CASH: 'cash-payment',
  PAY_AT_PICKUP: 'pay-at-pickup',
  SEND_PAYMENT_LINK: 'send-payment-link',
  CITY_VOUCHER: 'city-voucher',
  TERMINAL: 'terminal',
  ADMIN_PAYMENT: 'admin-payment' // This is used for admin payments - same logic as pay-at-pickup
}

export const CURRENCY_CODE = {
  EUR: 'EUR',
  CHF: 'CHF'
}

export const CURRENCY_SYMBOL = {
  EUR: '€',
  CHF: 'CHF'
}

// wings-payment-source-token is used as a dummy token
// as we don't need payment source token, but it's required by the commerce layer
export const PAYMENT_SOURCE_TOKEN = 'wings-payment-source-token'

export const DISCOUNT_TYPES = [
  'percentage_discount_promotions',
  'fixed_amount_promotions',
  'external_promotions',
  'flex_promotions'
]
